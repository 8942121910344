import React, { Component } from 'react'
import { Paper, Typography, TextField, Button } from '@material-ui/core';
import SweetAlert from 'sweetalert2-react';

export class AccountCompany extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showCompanySuccess: false,
            showCompanyError: false
        }
    }

    render() {

        return (
            <div className={this.props.hasCompanyProfile}  style={{ marginBottom: '20px' }}>
                <Paper className="card-body" elevation={0}>
                    <Typography variant="h6" style={{ marginBottom: '10px' }}>
                        Unternehmen
                    </Typography>
                    <TextField style={{ marginBottom: '20px' }} id="company-name" fullWidth className="d-block" type="text" label="Firmenname"/>
                    <TextField style={{ marginBottom: '20px' }} id="company-email" fullWidth className="d-block" type="text" label="E-Mail Adresse"/>
                    <TextField style={{ marginBottom: '20px' }} id="company-phone" fullWidth className="d-block" type="text" label="Telefonnummer"/>
                    <TextField style={{ marginBottom: '20px' }} id="company-address" fullWidth className="d-block" type="text" label="Straße"/>
                    <TextField style={{ marginBottom: '20px' }} id="company-postalcode" fullWidth className="d-block" type="text" label="Postleitzahl"/>
                    <TextField style={{ marginBottom: '20px' }} id="company-city" fullWidth className="d-block" type="text" label="Stadt"/>
                    <TextField style={{ marginBottom: '20px' }} id="company-state" fullWidth className="d-block" type="text" label="Bundesland"/>
                    <TextField style={{ marginBottom: '20px' }} id="company-country" fullWidth className="d-block" type="text" label="Land"/>
                    <TextField style={{ marginBottom: '0px' }} id="company-taxid" fullWidth className="d-block" type="text" label="USt.-ID"/>
                    <Button style={{ marginTop: '20px' }} variant="contained" color="primary" onClick={() => {
                        const authToken = localStorage.getItem("authToken");
                        fetch(`https://scapi.schnelle.cloud/sc/items/company_profile`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                            body: JSON.stringify({
                                status: 'new',
                                name: document.getElementById("company-name").value,
                                email: document.getElementById("company-email").value,
                                phone: document.getElementById("company-phone").value,
                                address: document.getElementById("company-address").value,
                                postalcode: document.getElementById("company-postalcode").value,
                                city: document.getElementById("company-city").value,
                                state: document.getElementById("company-state").value,
                                country: document.getElementById("company-country").value,
                                taxid: document.getElementById("company-taxid").value
                            })
                        })
                        .then(data => data.json())
                        .then(data => {
                            if(data.data) {
                                this.setState({ showCompanySuccess: true })
                                setTimeout(function(){ window.location.reload() }, 2000);
                            } else {
                                this.setState({ showCompanyError: true })
                            }
                        });
                    }}>Speichern</Button>
                </Paper>

                <SweetAlert
                show={this.state.showCompanySuccess}
                type="success"
                title="Anfrage erfolgreich"
                text="Informationen erfolgreich hinterlegt."
                onConfirm={() => this.setState({ showCompanySuccess: false })}
                />

                <SweetAlert
                show={this.state.showCompanyError}
                type="error"
                title="Fehlgeschlagen"
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ showCompanyError: false })}
                />

            </div>
        )
    }
}

export default AccountCompany
