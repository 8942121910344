import React, { Component } from 'react'
import { Row, Col, Form, Image } from 'react-bootstrap';
import { TextField, Paper, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import SweetAlert from 'sweetalert2-react';
import Loading from '../Loading';

export class Deploy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            keys: [],
            marketplace: {},
            selectedKeyId: null,
            selectedKeyKey: null,
            selectedKeyUser: null,
            sizeselected1: false,
            sizeselected2: false,
            sizeselected3: false,
            sizeselected4: false,
            sizeselected5: false,
            sizeselected6: false,
            sizeselected7: false,
            sizeselected: null
        }
    }

    componentDidMount() {
        const authToken = localStorage.getItem("authToken");
        fetch(`https://scapi.schnelle.cloud/sc/users/me`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } 
        });
        fetch(`https://scapi.schnelle.cloud/sc/items/marketplace/${this.props.match.params.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(data => data.json())
        .then(data => {
            if(data.data) {
                this.setState({ marketplace: data.data, loading: false })
                console.log(data.data)
            }
        });
        fetch('https://scapi.schnelle.cloud/sc/items/ssh_keys?sort=-id', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({keys: data.data})
                this.setState({loading: false})
            }
        });
    }

    render() {

        function handleServiceAmount() {
            if(marketplace.database_type === 'none') {
                return "1x";
            } else {
                return "2x";
            }
        }

        function handlePriceAmount(size) {
            if(marketplace.database_type === 'none') {
                if(size === 1){return '2,49 €'}
                if(size === 2){return '4,99 €'}
                if(size === 3){return '9,99 €'}
                if(size === 4){return '14,99 €'}
                if(size === 5){return '19,99 €'}
                if(size === 6){return '29,99 €'}
                if(size === 7){return '49,99 €'}
            } else {
                if(size === 1){return '4,99 €'}
                if(size === 2){return '9,99 €'}
                if(size === 3){return '19,99 €'}
                if(size === 4){return '29,99 €'}
                if(size === 5){return '39,99 €'}
                if(size === 6){return '59,99 €'}
                if(size === 7){return '99,99 €'}
            }
        }

        // loading animation
        if(this.state.loading) {return <Loading />}

        if(!this.state.marketplace.description) {return <Loading />}

        // no marketplace data
        if(!this.state.marketplace) {return <Loading />}

        // no key data
        if(!this.state.keys) {return <Loading />}

        // handle selected key id
        const handleChange = (event) => {
            this.setState({
              ...this.state,
              "selectedKeyId": event.target.value
            });
          };

        // populate data if key selected
        if(this.state.selectedKeyId !== null && this.state.selectedKeyKey === null) {
        const authToken = localStorage.getItem("authToken");
        fetch(`https://scapi.schnelle.cloud/sc/items/ssh_keys/${this.state.selectedKeyId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            })
            .then(data => data.json())
            .then(data => {
                if(data.data) {
                    this.setState({ selectedKeyKey: data.data.key, selectedKeyUser: data.data.user }) 
                }
            });
        }

        // const for marketplace data
        const marketplace = this.state.marketplace;

        // const for ssh keys
        const keys = this.state.keys.map((key) =>
            <MenuItem value={key.id}>{key.name}</MenuItem>
        );

        return (
            <div>
                <Row>

                <Col xs={12} style={{ marginBottom: '20px' }}>
                <Paper elevation={0} className="card-body" style={{ paddingBottom: '0px' }}>
                    <Row>

                    <Col xs={12} style={{ marginBottom: '0px' }}>
                    
                        <div className="d-flex flex-row">
                        <div className="deploy-header">
                        <Typography variant="h5">
                        <Image style={{ borderRadius: '50px', marginBottom: '3px', marginRight: '10px', border: '1px solid #eeeeee' }} height="30px" src={marketplace.image_url} />
                            {marketplace.name} ({marketplace.latest_version})
                            {marketplace.database_type !== "none" ? ( " + " + marketplace.database_type) : ('')}
                        </Typography>
                        </div>
                        <div className="ml-auto">
                        <Button  id="deploy-button" className="d-none d-md-block" variant="contained" color="primary" style={{ fontSize: '12px' }} onClick={() => {
                            const authToken = localStorage.getItem("authToken");

                            function handleApplicationName() {
                                if(document.getElementById("application-name").value){return document.getElementById("application-name").value;}
                           }

                            fetch(`https://scapi.schnelle.cloud/sc/items/applications`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                body: JSON.stringify({
                                    name: handleApplicationName(),
                                    template: this.state.marketplace.template,
                                    ssh_username: this.state.selectedKeyUser,
                                    ssh_publickey: this.state.selectedKeyKey,
                                    size: this.state.sizeselected,
                                    status: 'pending'
                                })
                            })
                            .then(data => data.json())
                            .then(data => {
                                if(data.data) {
                                    this.setState({ successAlert: true })
                                    setTimeout(function(){ window.location.href = "/"; }, 2000);
                                } else {
                                    this.setState({ errorAlert: true })
                                }
                            });
                        }}>
                            bereitstellen
                        </Button>
                        </div>
                        </div>
                        <Typography variant="div" style={{ fontSize: '14px' }}>
                            {marketplace.description.substr(0,835)}...
                            (Quelle: Wikipedia)
                        </Typography>
                        <hr style={{ marginTop: '20px', marginBottom: '25px' }} />
                        </Col>

                        <Col style={{ marginBottom: '10px' }} xs={12}>
                        <div className="d-flex flex-row">
                            <div>
                            <Typography variant="h6">Konfiguration</Typography>
                            </div>
                        </div>
                        </Col>

                        <Col xs={12} lg={3}>
                                <div>
                                <TextField autocomplete="off" fullWidth id="application-name" className="mr-4 mb-3 d-block" type="text" label="Name" />
                                </div>
                            </Col>
                            <Col xs={12} lg={3}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">SSH-Key (optional)</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={handleChange}
                                >

                                {keys}

                                </Select>
                            </FormControl>
                            </Col>
                        {/* <Col xs={12} lg={3}>
                                <div>
                                <TextField autocomplete="off" fullWidth id="application-ssh-username" className="mr-4 mb-3 d-block" type="text" label="SSH Username (optional)" />
                                </div>
                            </Col>
                            <Col xs={12} lg={3}>
                                <div>
                                <TextField fullWidth id="application-ssh-publickey" className="mr-4 mb-3 d-block" type="text" label="SSH Publickey (optional)"/>
                                </div>
                            </Col> */}

                    <Col className="mb-3" xs={12} style={{ marginTop: '30px' }}>
                    <div className="d-flex flex-row">
                        <div>
                        <Typography variant="h6">Performance</Typography>
                        </div>
                    </div>
                    </Col>

                        <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                            <Paper elevation={0} className="card-body sc-size-card">
                                <div style={{ marginBottom: '10px' }}>
                                <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected1} label={handlePriceAmount(1)} onClick={() =>
                                    {this.setState({ sizeselected1: true, sizeselected2: false, sizeselected3: false, sizeselected4: false, sizeselected5: false, sizeselected6: false, sizeselected7: false ,sizeselected: 1 })}
                                } />
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 0.5 vCPU
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 512M RAM
                                </div>
                            </Paper>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                            <Paper elevation={0} className="card-body sc-size-card">
                                <div style={{ marginBottom: '10px' }}>
                                <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected2} label={handlePriceAmount(2)} onClick={() =>
                                    {this.setState({ sizeselected1: false, sizeselected2: true, sizeselected3: false, sizeselected4: false, sizeselected5: false, sizeselected6: false, sizeselected7: false ,sizeselected: 2 })}
                                } />
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 1 vCPU
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 1GB RAM
                                </div>
                            </Paper>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                            <Paper elevation={0} className="card-body sc-size-card">
                                <div style={{ marginBottom: '10px' }}>
                                <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected3} label={handlePriceAmount(3)} onClick={() =>
                                    {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: true, sizeselected4: false, sizeselected5: false, sizeselected6: false, sizeselected7: false ,sizeselected: 3 })}
                                } />
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 1 vCPU
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 2GB RAM
                                </div>
                            </Paper>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                            <Paper elevation={0} className="card-body sc-size-card">
                                <div style={{ marginBottom: '10px' }}>
                                <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected4} label={handlePriceAmount(4)} onClick={() =>
                                    {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: false, sizeselected4: true, sizeselected5: false, sizeselected6: false, sizeselected7: false ,sizeselected: 4 })}
                                } />
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 1 vCPU
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 4GB RAM
                                </div>
                            </Paper>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                            <Paper elevation={0} className="card-body sc-size-card">
                                <div style={{ marginBottom: '10px' }}>
                                <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected5} label={handlePriceAmount(5)} onClick={() =>
                                    {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: false, sizeselected4: false, sizeselected5: true, sizeselected6: false, sizeselected7: false ,sizeselected: 5 })}
                                } />
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 2 vCPU
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 4GB RAM
                                </div>
                            </Paper>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                            <Paper elevation={0} className="card-body sc-size-card">
                                <div style={{ marginBottom: '10px' }}>
                                <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected6} label={handlePriceAmount(6)} onClick={() =>
                                    {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: false, sizeselected4: false, sizeselected5: false, sizeselected6: true, sizeselected7: false ,sizeselected: 6 })}
                                } />
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 2 vCPU
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 8GB RAM
                                </div>
                            </Paper>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                            <Paper elevation={0} className="card-body sc-size-card">
                                <div style={{ marginBottom: '10px' }}>
                                <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected7} label={handlePriceAmount(7)} onClick={() =>
                                    {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: false, sizeselected4: false, sizeselected5: false, sizeselected6: false, sizeselected7: true ,sizeselected: 7 })}
                                } />
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 4 vCPU
                                </div>
                                <div className="sc-size-info">
                                    {handleServiceAmount()} 8GB RAM
                                </div>
                            </Paper>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-block d-md-none" style={{ paddingBottom: '20px' }}>
                        <Button id="deploy-button" className="d-block d-md-none" variant="contained" color="primary" style={{ fontSize: '12px' }} onClick={() => {
                            const authToken = localStorage.getItem("authToken");

                            function handleApplicationName() {
                                if(document.getElementById("application-name").value){return document.getElementById("application-name").value;}
                           }

                            fetch(`https://scapi.schnelle.cloud/sc/items/applications`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                body: JSON.stringify({
                                    name: handleApplicationName(),
                                    template: this.state.marketplace.template,
                                    ssh_username: this.state.selectedKeyUser,
                                    ssh_publickey: this.state.selectedKeyKey,
                                    size: this.state.sizeselected,
                                    status: 'pending'
                                })
                            })
                            .then(data => data.json())
                            .then(data => {
                                if(data.data) {
                                    this.setState({ successAlert: true })
                                    setTimeout(function(){ window.location.href = "/"; }, 2000);
                                } else {
                                    this.setState({ errorAlert: true })
                                }
                            });
                        }}>
                            bereitstellen
                        </Button>
                        </Col>
                    </Row>
                </Paper>
            </Col>
            
            <SweetAlert
            show={this.state.successAlert}
            type="success"
            title="Anfrage erfolgreich"
            text="Deine Anwendung wird in kürze bereitgestellt."
            onConfirm={() => this.setState({ successAlert: false })}
            />

            <SweetAlert
            show={this.state.errorAlert}
            type="error"
            title="Fehlgeschlagen"
            text="Überprüfe deine Angaben und versuche es erneut."
            onConfirm={() => this.setState({ errorAlert: false })}
            />

        </Row>
            </div>
        )
    }
}

export default Deploy
