import React, { Component } from 'react'
import { Paper, Typography, TextField, Button } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
var QRCode = require('qrcode.react');
var speakeasy = require('speakeasy');

export class TwoFactor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show2FAError: false,
            show2FASuccess: false,
            userData: null,
            show2FAModal: false,
            faenabled: false
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/users/me', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({userData: data.data})
                this.setState({loading: false})
                if(data.data['2fa_secret'] !== null){
                    this.setState({ faenabled: true })
                    console.log("2fa enabled")
                }
            }
        });
    }

    render() {

        const secret = speakeasy.generateSecret({length: 20, issuer: 'schnellecloud', label: this.props.mail});

        function handle2FASetupText(state){
            if(state === true){
                return 'd-none';
            }
            if(state === false){
                return 'd-block';
            }
        }

        function handle2FAButtonText(state){
            if(state === false){
                return 'Einrichten';
            }
            if(state === true){
                return 'Deaktivieren';
            }
        }

        function handleButtonColor(state){
            if(state === true){
                return 'secondary'
            }
            if(state === false) {
                return 'primary'
            }
        }

        return (
            <div>
                <Paper className="card-body" elevation={0}>
                    <Typography variant="h6">
                        Zwei-Faktor-Authentifizierung
                    </Typography>
                    <div style={{ marginTop: '10px', marginBottom: '0px' }} className={handle2FASetupText(this.state.faenabled)}>
                    <p style={{ fontSize: '14px', color: '#505050' }}>Die Zwei-Faktor-Authentifizierung (2FA) ist eine Kombination aus zwei unterschiedlichen und voneinander getrennten Komponenten, die der Authentifizierung eines Benutzers dienen. Nur wenn beide Komponenten stimmen, wird eine Aktion ausgeführt. Ist eine Komponente (z. B. der Computer) kompromittiert, ist nicht automatisch die zweite Komponente gefährdet.</p>
                    <p style={{ fontSize: '14px', color: '#505050' }}>Zur Verwendung der Zwei-Faktor-Authentifizierung benötigen Sie einen OTP-Client (Software), welchen Sie auf einem Mobilgerät wie z.B. einem Smartphone installieren. Wir empfehlen die kostenlose mobile App “Google Authenticator”</p>
                    <div className="d-flex flex-row">
                    <a target="_blank" href="https://apps.apple.com/de/app/google-authenticator/id388497605"><img style={{ height: '30px', marginRight: '10px' }} src="https://t86r391ab0nkqzf0sl.scprev1.de/web/homepage/laden-im-appstore.png"/></a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"><img style={{ height: '30px' }} src="https://t86r391ab0nkqzf0sl.scprev1.de/web/homepage/get-it-on-google-play.png"/></a>
                    </div>
                    </div>
                    <Button style={{ marginTop: '20px' }} variant="contained" color={handleButtonColor(this.state.faenabled)} onClick={() => {
                        if(this.state.faenabled === true) {
                            const authToken = localStorage.getItem("authToken");
                            fetch(`https://scapi.schnelle.cloud/sc/users/${this.props.userid}`, {
                                method: 'PATCH',
                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                body: JSON.stringify({
                                    "2fa_secret": null,
                                })
                            })
                            .then(data => data.json())
                            .then(data => {
                                if(data.error) {
                                } else {
                                    this.setState({ faenabled: true })
                                    const authToken = localStorage.getItem("authToken");
                                    fetch('https://scapi.schnelle.cloud/sc/users/me', {
                                        method: 'GET',
                                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    })
                                    .then(data => data.json())
                                    .then(data => {
                                        if(data.error) {
                                        } else {
                                            this.setState({show2FASuccess: true})
                                            if(data.data['2fa_secret'] !== null){
                                                this.setState({ faenabled: true })
                                            }
                                            else {
                                                this.setState({ faenabled: false })
                                            }
                                        }
                                    });
                                }
                            });
                        }
                        if(this.state.faenabled === false) {
                            this.setState({ show2FAModal: true })
                        }
                    }}>{handle2FAButtonText(this.state.faenabled)}</Button>
                </Paper>

                <SweetAlert
                show={this.state.show2FAError}
                type="error"
                title="Fehlgeschlagen"
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ show2FAError: false })}
                />

                <SweetAlert
                show={this.state.show2FASuccess}
                type="success"
                title="Anfrage erfolgreich"
                text="Authentifizierungsmethode erfolgreich geändert."
                onConfirm={() => this.setState({ show2FASuccess: false })}
                />

                <Modal show={this.state.show2FAModal}>
                    <Modal.Header closeButton onClick={() => {this.setState({ show2FAModal: false })}}>
                    <Modal.Title>Einrichtung: 2FA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>1) Aus Sicherheitsgründen sollten Sie sich folgenden Wiederherstellungsschlüssel notieren oder ausdrucken:</p>
                        <TextField style={{ marginTop: '20px' }} id="2fa-user" fullWidth className="d-block" type="text" label="Login" value={this.props.mail}/>
                        <TextField style={{ marginTop: '20px' }} id="2fa-secret" fullWidth className="d-block" type="text" label="Schlüssel" value={secret.base32}/>
                        <p style={{ marginTop: '20px' }}>2) Scannen Sie den folgenden QR-Code über die Authenticator App Ihrer Wahl und bestätigen Sie die Einrichtung durch Eingabe des in der App angezeigten Codes:</p>
                        <QRCode size="200" value={"otpauth://totp/schnelle.cloud:" + this.props.mail + "?secret=" + secret.base32 + "&issuer=schnelle.cloud"} />
                        <TextField style={{ marginTop: '20px' }} id="verify-otp" fullWidth className="d-block" type="text" label="OTP Bestätigung"/>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button id="cancel-remove-application-button" variant="secondary" onClick={() => {
                        this.setState({ show2FAModal: false })
                    }}>
                        Abbrechen
                    </Button>
                    <Button id="remove-application-button" className="primary" variant="primary" onClick={() => {
                            var verified = speakeasy.totp.verify({ secret: secret.base32,
                            encoding: 'base32',
                            token: document.getElementById("verify-otp").value });
                            if(verified === true){
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/users/${this.props.userid}`, {
                                    method: 'PATCH',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        "2fa_secret": secret.base32,
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.error) {
                                    } else {
                                        this.setState({ show2FAModal: false })
                                        this.setState({faenabled: true})
                                        this.setState({show2FASuccess: true})
                                    }
                                });
                            } else {
                                this.setState({show2FAError: true})
                            }
                    }}>
                        Einrichtung Abschließen
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default TwoFactor
