import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

export class Navigation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loggedin: 'd-none',
            showUserDropdown: false
        }
    }

    componentDidMount(){
        localStorage.getItem("authToken") ? (this.setState({loggedin: 'd-block'})) : (this.setState({loggedin: 'd-none'}))
    }
    
    render() {

        return (
            <div>
                <Navbar bg="primary" variant="dark">
                    <Container>
                        <Link to="/" className="navbar-brand">
                            <Image style={{ marginBottom: '5px' }} height="24px" src="https://content.schnelle.cloud/uploads/d40f96dc7e7a44f2a961db7725063b16.png" />
                        </Link>
                        <Nav className="ml-auto d-flex align-items-center py-1">
                            <div className={this.state.loggedin}>
                                <Link to="/marketplace" className="nav-link d-none d-md-block">
                                    <div className="shadow-sm" style={{ background: 'white', borderRadius: '15px', padding: '5px 15px', color: '#0088C8', fontSize: '12px', fontWeight: '600', textTransform: 'uppercase' }}>
                                        <div>
                                            <FontAwesomeIcon style={{ fontSize: '10px', color: '#0088C8', marginRight: '10px', fontWeight: '400' }} icon={faPlus} />
                                            <span style={{ color: '#0088C8' }}>Anwendung</span>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/marketplace" className="nav-link d-block d-md-none">
                                    <div className="shadow-sm" style={{ background: 'white', borderRadius: '15px', padding: '5px 15px', color: '#0088C8', fontSize: '12px', fontWeight: '600', textTransform: 'uppercase' }}>
                                        <div>
                                            <FontAwesomeIcon style={{ fontSize: '12px', color: '#0088C8', marginRight: '0px', fontWeight: '400' }} icon={faPlus} />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className={this.state.loggedin}>
                                <div className="d-flex align-items-center justify-content-center shadow-sm ml-1" style={{ background: 'white', borderRadius: '50%', width: '28px', height: '28px' }}>
                                    <Link to="/account">
                                        <FontAwesomeIcon style={{ fontSize: '14px', color: '#0088C8', cursor: 'pointer' }} icon={faUser} onClick={() =>{
                                            if(this.state.showUserDropdown === true){
                                                this.setState({ showUserDropdown: false })
                                            }
                                            if(this.state.showUserDropdown === false){
                                            this.setState({ showUserDropdown: true })
                                        }
                                        }} />
                                    </Link>
                                </div>
                            </div>
                            <div className={this.state.loggedin}>
                                <div style={{ marginLeft: '15px' }}>
                                    <FontAwesomeIcon title="Ausloggen" style={{ fontSize: '14px', color: 'rgba(255,255,255, 0.8)', cursor: 'pointer' }} icon={faSignOutAlt} onClick={() => {
                                        localStorage.removeItem("authToken")
                                        window.location = "/"
                                    }}/>
                                </div>
                            </div>
                        </Nav>
                    </Container>
                </Navbar>
            </div>
        )
    }
}

export default Navigation
