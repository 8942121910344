import React, { Component } from 'react';
import {  Paper, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

export class DatabaseView extends Component {

    render() {

        function getDatabaseURL( type, url) {
            if(type === 'mongodb'){
                let prefix = "http://mongoexpress.";
                let databaseurl = prefix + url
                return databaseurl;
            }
            if(type === 'mariadb'){
                let prefix = "http://";
                let suffix = '/phpmyadmin/'
                let databaseurl = prefix + url + suffix
                return databaseurl;
            }
            if(type === 'mysql'){
                let prefix = "http://";
                let suffix = '/phpmyadmin/'
                let databaseurl = prefix + url + suffix
                return databaseurl;
            }
            if(type === 'none'){
                let databaseurl = ''
                return databaseurl;
            }
        }

        function showdb(type) {
            if(type === 'none') {
                return 'd-none';
            }
        }

        return (
            <div className={showdb(this.props.application.database_type)}>
                <Paper elevation={0} className="card-body">
                    <div style={{ marginBottom: '5px' }} className="d-flex flex-row align-items-center">
                        <div>
                        <Typography variant="h6" style={{ paddingBottom: '0px' }}>
                            Datenbank
                        </Typography>
                        </div>
                        <div className="ml-auto" style={{ color: '#505050', fontSize: '14px' }}>
                        <a rel="noopener noreferrer" target="_blank" style={{ fontSize: '15px' }} href={getDatabaseURL(this.props.application.database_type, this.props.application.url)}>
                        <FontAwesomeIcon style={{ fontSize: '14px', color: '#999999' }} icon={faCog} />
                            </a>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div>
                            <div style={{ fontSize: '15px' }} >{this.props.application.database}</div>
                        </div>
                        <div className="ml-auto">
                        {/* <a style={{ fontSize: '15px' }} target="_blank" href={getDatabaseURL(this.props.application.database_type, this.props.application.url)}>
                            <FontAwesomeIcon style={{ fontSize: '14px', color: '#0088C8' }} icon={faCog} />
                            </a> */}
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }
}

export default DatabaseView
