import React, { Component } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import { Paper} from '@material-ui/core';
import Invoices from '../../account/Invoices';
import AccountCompany from '../../account/AccountCompany';
import AccountSSH from '../../account/AccountSSH';
import ChangePassword from '../../account/ChangePassword';
import TwoFactor from '../../account/TwoFactor';
import Support from '../../account/Support';
import BillingLog from '../../account/BillingLog';
import Credits from '../../account/Credits';
import Transactions from '../../account/Transactions';
import Transfer from '../../account/Transfer';
import Loading from '../Loading';

export class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            companyProfile: {},
            hasCompanyProfile: 'd-none',
            showRechnungen: false,
            showTransfer: false,
            showAccount: false,
            showSSH: false,
            showSupport: false,
            phonecall: false,
            showGuthaben: false,
            showTransaktionen: false,
            showBillingLogs: false,
            userID: null,
            userMail: null,
            showPWError: false,
            showPWSuccess: false,
            active: 'showRechnungen'
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/items/company_profile', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({companyProfile: data.data})
                if(data.data.length === 0) {
                    this.setState({hasCompanyProfile: 'd-block'})
                }
                this.setState({loading: false})
            }
        });
        fetch('https://scapi.schnelle.cloud/sc/users/me', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({userID: data.data.id})
                this.setState({userMail: data.data.email})
                this.setState({loading: false})
            }
        });
    }

    render() {

        if(this.state.loading) {return <Loading />}

        function handleactive(tab,activetab) {
            if(tab === activetab) {
                return 'd-block';
            } else {
                return 'd-none';
            }
        }

        return (
            <div>
                <Row>
                    <Col xs={12} style={{ marginBottom: '20px' }}>
                        <Paper elevation={0}>
                            <Nav justify variant="tabs" defaultActiveKey="/home">
                            <div className={"nav-item"}>
                                <div className="nav-link" onClick={() => {
                                    this.setState({ showRechnungen: true, showGuthaben: false, showTransaktionen: false, showGutscheine: false, showTransfer:false, showAccount: false, showSupport: false, showBillingLogs: false, showSSH: false,  active: 'showRechnungen' })
                                }} >Rechnungen</div>
                            </div>
                            <div className={"nav-item"}>
                                <div className="nav-link" onClick={() => {
                                    this.setState({ showRechnungen: false, showGuthaben: true, showTransaktionen: false, showGutscheine: false, showTransfer:false, showAccount: false, showSupport: false, showBillingLogs: false, showSSH: false,  active: 'showGuthaben'})
                                }} >Guthaben</div>
                            </div>
                            <div className={"nav-item"}>
                                <div className="nav-link" onClick={() => {
                                    this.setState({ showRechnungen: false, showGuthaben: false, showTransaktionen: true, showGutscheine: false, showTransfer:false, showAccount: false, showSupport: false, showBillingLogs: false, showSSH: false,  active: 'showTransaktionen'})
                                }} >Transaktionen</div>
                            </div>
                            <div className={"nav-item"}>
                                <div className="nav-link" onClick={() => {
                                    this.setState({ showRechnungen: false, showGuthaben: false, showTransaktionen: false, showGutscheine: false, showTransfer:false, showAccount: false, showSupport: false, showBillingLogs: true, showSSH: false,  active: 'showBillingLog'})
                                }} >Kosten</div>
                            </div>
                            <div className={"nav-item"}>
                                <div className="nav-link" onClick={() => {
                                    this.setState({ showRechnungen: false, showGuthaben: false, showTransaktionen: false, showGutscheine: false, showTransfer:false, showAccount: false, showSupport: false, showBillingLogs: false, showSSH: true,  active: 'showSSH'})
                                }} >SSH-Zugang</div>
                            </div>
                            <div className={"nav-item"}>
                                <div className="nav-link" onClick={() => {
                                    this.setState({ showRechnungen: false, showGuthaben: false, showTransaktionen: false, showGutscheine: false, showTransfer:false, showAccount: true, showSupport: false, showBillingLogs: false, showSSH: false,  active: 'showAccount'})
                                }} >Account</div>
                            </div>
                            <div className={"nav-item"}>
                                <div className="nav-link" onClick={() => {
                                    this.setState({ showRechnungen: false, showGuthaben: false, showTransaktionen: true, showGutscheine: false, showTransfer:false, showAccount: false, showSupport: false, showBillingLogs: false, showSSH: false,  active: 'showTransfer'})
                                }} >Transfer</div>
                            </div>
                            <div className={"nav-item"}>
                                <div className="nav-link" onClick={() => {
                                    this.setState({ showRechnungen: false, showGuthaben: false, showTransaktionen: false, showGutscheine: false, showTransfer:false, showAccount: false, showSupport: true, showBillingLogs: false, showSSH: false,  active: 'showSupport'})
                                }} >Support</div>
                            </div>
                            </Nav>
                        </Paper>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col className={handleactive("showRechnungen", this.state.active)} xs={12} style={{ marginBottom: '20px' }}>
                                <Invoices />
                            </Col>
                            <Col className={handleactive("showBillingLog", this.state.active)} xs={12} style={{ marginBottom: '20px' }}>
                                <BillingLog />
                            </Col>
                            <Col className={handleactive("showGuthaben", this.state.active)} xs={12} style={{ marginBottom: '20px' }}>
                                <Credits />
                            </Col>
                            <Col className={handleactive("showTransaktionen", this.state.active)} xs={12} style={{ marginBottom: '20px' }}>
                                <Transactions />
                            </Col>
                            <Col className={handleactive("showTransfer", this.state.active)} xs={12} style={{ marginBottom: '20px' }}>
                                <Transfer />
                            </Col>
                            <Col className={handleactive("showSupport", this.state.active)} xs={12} style={{ marginBottom: '20px' }}>
                                <Support />
                            </Col>
                            <Col className={handleactive("showAccount", this.state.active)} xs={12} style={{ marginBottom: '0px' }}>
                                <AccountCompany hasCompanyProfile={this.state.hasCompanyProfile}/>
                            </Col>
                            <Col xs={12} className={handleactive("showAccount", this.state.active)} style={{ marginBottom: '20px' }}>
                                <ChangePassword/>
                            </Col>
                            <Col xs={12} className={handleactive("showAccount", this.state.active)} style={{ marginBottom: '20px' }}>
                                <TwoFactor userid={this.state.userID} mail={this.state.userMail} />
                            </Col>
                            <Col xs={12} className={handleactive("showSSH", this.state.active)} style={{ marginBottom: '20px' }}>
                                <AccountSSH/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Account
