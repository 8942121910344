import React, { Component } from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import Loading from '../Loading';

// Create styles
  
export class InvoiceSingle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            invoice: {}
        }
    }

    componentDidMount() {
        const authToken = localStorage.getItem("authToken");
        fetch(`https://scapi.schnelle.cloud/sc/items/rechnungen/${this.props.match.params.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                window.location = "/account"
            } else {
                this.setState({invoice: data.data})
                this.setState({loading: false})
            }
        });
    }
    
    render() {

        function handleItemAmountName(item_id) {
            if(item_id === 'Guthaben') {
                return 'Stück'
            }
            if(item_id === 'Gutschein') {
                return 'Stück'
            } else {
                return 'Minuten'
            }
        }

        if(this.state.loading) {return <Loading />}

        if(!this.state.invoice) {return <Loading />}

        const positions = this.state.invoice.items.map((position, key) => 
            <View key={key} style={{display: 'flex-inline', flexDirection: 'row', paddingBottom: '5px'}}>
                <Text style={{ fontSize: '10', margin: '0', padding: '0', width: '120' }} >{position.position}</Text>
                <Text style={{ fontSize: '10', margin: '0', padding: '0', width: '120' }} >{position.item_id}</Text>
                <Text style={{ fontSize: '10', margin: '0', padding: '0', width: '220' }} >{position.total_minutes} {handleItemAmountName(position.item_id)}</Text>
                <Text style={{ fontSize: '10', margin: '0', padding: '0', width: '220' }} >{position.total_costs} €</Text>
            </View>
        );

        function handleTaxAmount(gross, net) {
            let taxamount = gross - net
            taxamount = taxamount.toFixed(2)
            return(taxamount)
        }

        function handeDecimalAmount(amount) {
            amount = amount - 0
            amount = amount.toFixed(2)
            return amount
        }

        function handleCompanyName(company_name){
            if (company_name === 'null'){
                return '-10000'
            }
            else {
                return '0'
            }
        }

        function handleTaxId(taxid)  {
            if (taxid === 'null'){
                return '-10000'
            }
            else {
                return '0'
            }
        }

        return (
            <div>
                <PDFViewer style={{ width: '100%', height: '1150px', marginBottom: 10, border: '0px solid transparent', borderRadius: '5px' }}>
                    <Document>
                        <Page size="A4" style={{backgroundColor: '#f9f9f9', paddingTop: 20, paddingBottom: 60}}>
                            <View style={{display: 'flex', flexDirection: 'row', marginLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10, paddingTop: 100}}>
                                <View>
                                    <Text style={{ fontSize: '8', marginBottom: '10' }} >{this.state.invoice.intern_name + " - " + this.state.invoice.intern_address + " - " + this.state.invoice.intern_postalcode + " " + this.state.invoice.intern_city}</Text>
                                    <Text hidden className="d-none" style={{ fontSize: '12', marginBottom: '2', left: handleCompanyName(this.state.invoice.company_name) }} >{this.state.invoice.company_name ? (this.state.invoice.company_name) : ('')}</Text>
                                    <Text style={{ fontSize: '12', marginBottom: '2' }} >{this.state.invoice.name}</Text>
                                    <Text style={{ fontSize: '12', marginBottom: '2' }} >{this.state.invoice.address}</Text>
                                    <Text style={{ fontSize: '12', marginBottom: '2' }} >{this.state.invoice.postalcode + " " + this.state.invoice.city}</Text>
                                </View>
                                <View style={{marginLeft: 'auto'}}>
                                    <Text style={{ fontSize: '12', marginBottom: '3', marginLeft: 'auto' }} >{'Rechnungsdatum: ' + this.state.invoice.invoice_date}</Text>
                                    <Text style={{ fontSize: '12', marginBottom: '3', marginLeft: 'auto' }} >{'Fälligkeitsdatum: ' + this.state.invoice.due_date}</Text>
                                    <Text style={{ fontSize: '12', marginBottom: '10', marginLeft: 'auto' }} >{'Rechnungsnummer: ' + this.state.invoice.id}</Text>
                                    {<Text style={{ fontSize: '12', marginBottom: '20', marginLeft: 'auto', right: handleTaxId(this.state.invoice.taxid) }} >{'USt-ID: ' + this.state.invoice.taxid}</Text>}
                                </View>
                            </View>
                            <View style={{ marginLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10, marginBottom: 30, marginTop: 60}}>
                                <Text style={{ fontSize: '22', marginBottom: '0' }} >
                                    Rechnung {this.state.invoice.id}
                                    {this.state.invoice.status === 'canceled' ? (' (STORNO)') : ('')}
                                    {this.state.invoice.status === 'paid' ? (' (bezahlt ' + this.state.invoice.transaction_reference) + ')' : ('')}
                                </Text>
                            </View>
                            <View style={{ marginLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10}}>
                                <View style={{display: 'flex-inline', flexDirection: 'row'}}>
                                    <Text style={{ fontSize: '10', marginBottom: '0', width: '120', fontWeight: '800' }} >Position</Text>
                                    <Text style={{ fontSize: '10', marginBottom: '0', width: '120', fontWeight: '800' }} >Beschreibung</Text>
                                    <Text style={{ fontSize: '10', marginBottom: '0', width: '220', fontWeight: '800' }} >Menge</Text>
                                    <Text style={{ fontSize: '10', marginBottom: '0', width: '250', fontWeight: '800' }} >Kosten (Brutto)</Text>
                                </View>
                                <View style={{backgroundColor: '#eeeeee', height: '2px', marginTop: '5px', marginBottom: '5px'}}></View>
                            </View>
                            <View style={{ marginLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10}}>
                                <View style={{display: 'flex', flexDirection: 'column'}}>
                                    {positions}
                                </View>
                            </View>
                            <View style={{backgroundColor: '#eeeeee', height: '2px', marginTop: '5px', marginBottom: '5px'}}></View>
                            <View wrap={false}>
                                <View style={{display: 'flex', flexDirection: 'row', marginLeft: 10, paddingLeft: 10, paddingRight: 50, marginRight: 10, paddingTop: 10}}>
                                    <Text style={{ marginBottom: '10', marginLeft: 'auto' }}>Summe Brutto: {handeDecimalAmount(this.state.invoice.gross_amount)} €</Text>
                                </View>
                                <View style={{display: 'flex', flexDirection: 'column', marginLeft: 10, paddingLeft: 10, paddingRight: 50, marginRight: 10, paddingTop: 5, paddingBottom: 10}}>
                                    <Text style={{ fontSize: '12', marginBottom: '5', marginLeft: 'auto' }}>Umsatzsteuersatz: 19 %</Text>
                                    <Text style={{ fontSize: '12', marginBottom: '5', marginLeft: 'auto' }}>Summe Netto: {handeDecimalAmount(this.state.invoice.net_amount)} €</Text>
                                    <Text style={{ fontSize: '12', marginBottom: '0', marginLeft: 'auto' }}>Umsatzsteueranteil: {handleTaxAmount(this.state.invoice.gross_amount,this.state.invoice.net_amount)} €</Text>
                                </View>
                            </View>
                            <View style={{backgroundColor: '#eeeeee', height: '2px', marginTop: '5px', marginBottom: '5px'}}></View>
                            <View wrap={false}>
                                <View style={{display: 'flex', flexDirection: 'column', marginLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10, paddingTop: 30, marginBottom: 0}}>
                                    <Text style={{ fontSize: '10', marginBottom: '10' }}>{this.state.invoice.note}</Text>
                                </View>
                                <View style={{display: 'flex', flexDirection: 'column', marginLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10, paddingTop: 20}}>
                                    <Text style={{ marginBottom: '10' }}>Zahlungsinformationen</Text>
                                    <Text style={{ fontSize: '10', marginBottom: '10' }}>Zahlungsart: {this.state.invoice.payment_type}</Text>
                                    <Text style={{ fontSize: '10', marginBottom: '3' }}>Kontoinhaber: {this.state.invoice.intern_kontoinhaber}</Text>
                                    <Text style={{ fontSize: '10', marginBottom: '10' }}>IBAN: {this.state.invoice.intern_iban}</Text>
                                    <Text style={{ fontSize: '10', marginBottom: '10' }}>Verwendungszweck: {this.state.invoice.intern_reference}</Text>
                                </View>
                            </View>
                            <View fixed={true} style={{ display: 'flex', flexDirection: 'col', position: 'absolute', bottom: 0, marginLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10 }}>
                                <Text style={{ fontSize: '8', marginBottom: '3' }}>R{ this.state.invoice.id } / Flippink E-Commerce Solutions UG - Tannenwaldallee 50 - 61348 Bad Homburg / USt-ID: DE815741221 / Amtsgericht Bad Homburg / HRB14115</Text>
                                <Text style={{ fontSize: '8', marginBottom: '10' }}>Telefonnummer: +49 6172 8868866 11 / E-Mail: info@mail.schnelle.cloud / Webseite: schnelle.cloud / Geschäftsführer: Adrian Filipow</Text>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        )
    }
}

export default InvoiceSingle
