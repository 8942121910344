import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Paper, Typography } from '@material-ui/core';

export class ResourceCount extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            domains: {},
            dbs: {}
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch(`https://scapi.schnelle.cloud/sc/items/domains`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({domains: data.data})
            }
        });
        setInterval(() => {
            fetch(`https://scapi.schnelle.cloud/sc/items/domains`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({domains: data.data})
            }
        });
          }, 30000);
        fetch(`https://scapi.schnelle.cloud/sc/items/applications?filter[database_type][neq]=none`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({dbs: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch(`https://scapi.schnelle.cloud/sc/items/applications?filter[database_type][neq]=none`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({dbs: data.data})
                this.setState({loading: false})
            }
        });
          }, 30000);
    }

    render() {

        return (
            <Row style={{ marginBottom: '20px' }}>
                <Col xs={12} lg={4} style={{ marginBottom: '1px' }}>
                    <Paper className="card-body" elevation={0}>
                        <div className="d-flex flex-row align-items-center">
                            <div>
                                <Typography variant="h6">
                                    Anwendungen
                                </Typography>
                            </div>   
                            <div className="ml-auto application-count-count">
                                {this.props.applications.length}
                            </div>                              
                        </div>
                    </Paper>
                </Col>

                <Col xs={12} lg={4} style={{ marginBottom: '1px' }}>
                    <Paper className="card-body" elevation={0}>
                        <div className="d-flex flex-row align-items-center">
                            <div>
                                <Typography variant="h6">
                                    Domains
                                </Typography>
                            </div>   
                            <div className="ml-auto application-count-count">
                                {this.state.domains.length}
                            </div>                              
                        </div>
                    </Paper>
                </Col>

                <Col xs={12} lg={4} style={{ marginBottom: '0px' }}>
                    <Paper className="card-body" elevation={0}>
                        <div className="d-flex flex-row align-items-center">
                            <div>
                                <Typography variant="h6">
                                    Datenbanken
                                </Typography>
                            </div>   
                            <div className="ml-auto application-count-count">
                            {this.state.dbs.length}
                            </div>                              
                        </div>
                    </Paper>
                </Col>

            </Row>
        )
    }
}

export default ResourceCount
