import React, { Component } from 'react'
import { Paper, Typography, TextField, Button } from '@material-ui/core';
import SweetAlert from 'sweetalert2-react';

export class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPWError: false,
            showPWSuccess: false,
            userID: null
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/users/me', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({userID: data.data.id})
                console.log(data.data.id)
                this.setState({loading: false})
            }
        });
    }

    render() {

        return (
            <div>
                <Paper className="card-body" elevation={0}>
                    <Typography variant="h6" style={{ marginBottom: '10px' }}>
                        Passwort ändern
                    </Typography>
                    <TextField style={{ marginBottom: '20px' }} id="new-password" fullWidth className="d-block" type="password" label="New Passwort"/>
                    <TextField style={{ marginBottom: '0px' }} id="confirm-new-password" fullWidth className="d-block" type="password" label="New Passwort"/>
                    <Button style={{ marginTop: '20px' }} variant="contained" color="secondary" onClick={() => {
                        const authToken = localStorage.getItem("authToken");
                        let userid = this.state.userID;
                        let password = document.getElementById("new-password").value;
                        let newpassword = document.getElementById("confirm-new-password").value;

                        if(password === newpassword && password.length >= 6) {
                            fetch(`https://scapi.schnelle.cloud/sc/users/${userid}`, {
                                method: 'PATCH',
                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                body: JSON.stringify({
                                    password: password,
                                })
                            })
                            .then(data => data.json())
                            .then(data => {
                                if(data.data) {
                                    this.setState({ showPWSuccess: true })
                                    setTimeout(function(){ window.location.reload() }, 2000);
                                } 
                            });
                        } else {
                            this.setState({ showPWError: true })
                        }
                    }}>Passwort ändern</Button>
                </Paper>

                <SweetAlert
                show={this.state.showPWError}
                type="error"
                title="Fehlgeschlagen"
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ showPWError: false })}
                />

                <SweetAlert
                show={this.state.showPWSuccess}
                type="success"
                title="Anfrage erfolgreich"
                text="Deine Passwort wurde erfolgreich aktualisiert."
                onConfirm={() => this.setState({ showPWSuccess: false })}
                />
            </div>
        )
    }
}

export default ChangePassword
