import React, { Component } from 'react';
import { Row, Col, Badge, Form, Dropdown, Modal } from 'react-bootstrap';
import { TextField, Paper, Typography, Button } from '@material-ui/core';
import DomainsView from '../applications/DomainsView';
import DatabaseView from '../applications/DatabaseView';
import ApplicationSafety from '../applications/ApplicationSafety';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faLock, faLockOpen, faCog, faTerminal } from '@fortawesome/free-solid-svg-icons';
import SweetAlert from 'sweetalert2-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loading from '../Loading';

export class ApplicationSingle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            application: {},
            sizeselected1: false,
            sizeselected2: false,
            sizeselected3: false,
            sizeselected4: false,
            sizeselected5: false,
            sizeselected6: false,
            sizeselected7: false,
            sizeselected: 0,
            showRemoveModal: false,
            showSSLModal: false,
            showRestartModal: false,
            showTransferModal: false,
            showLockModal: false,
            showRemoveSuccessAlert: false,
            showApplicationError: false,
            copySSHValue: '',
            copySSHCopied: false,
            successAlert: false,
            errorAlert: false
        }
    }

    componentWillMount() {
        const authToken = localStorage.getItem("authToken");
        fetch(`https://scapi.schnelle.cloud/sc/users/me`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } 
        });
    }

    componentDidMount() {
        const authToken = localStorage.getItem("authToken");
        fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.match.params.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                window.location = "/"
            } else {
                this.setState({application: data.data})
                this.setState({loading: false})
                this.setState({sizeselected: this.state.application.size})
                if(this.state.application.size === 1){
                    this.setState({sizeselected1: true})
                }
                if(this.state.application.size === 2){
                    this.setState({sizeselected2: true})
                }
                if(this.state.application.size === 3){
                    this.setState({sizeselected3: true})
                }
                if(this.state.application.size === 4){
                    this.setState({sizeselected4: true})
                }
                if(this.state.application.size === 5){
                    this.setState({sizeselected5: true})
                }
                if(this.state.application.size === 6){
                    this.setState({sizeselected6: true})
                }
                if(this.state.application.size === 7){
                    this.setState({sizeselected7: true})
                }
            }
        });
        setInterval(() => {
            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.match.params.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                window.location = "/"
            } else {
                this.setState({application: data.data})
                this.setState({loading: false})
                this.setState({sizeselected: this.state.application.size})
                if(this.state.application.size === 1){
                    this.setState({sizeselected1: true})
                }
                if(this.state.application.size === 2){
                    this.setState({sizeselected2: true})
                }
                if(this.state.application.size === 3){
                    this.setState({sizeselected3: true})
                }
                if(this.state.application.size === 4){
                    this.setState({sizeselected4: true})
                }
                if(this.state.application.size === 5){
                    this.setState({sizeselected5: true})
                }
                if(this.state.application.size === 6){
                    this.setState({sizeselected6: true})
                }
                if(this.state.application.size === 7){
                    this.setState({sizeselected7: true})
                }
            }
        });
          }, 30000);
    }
    
    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.application) {return <Loading />}

        const application = this.state.application;

        function handleDBCol(type) {
            if(application.database_type === 'none'){
                return 12;
            } else {
                return 6;
            }
        }

        function handleDBColVis(type) {
            if(application.database_type === 'none'){
                return 'd-none';
            } else {
                return 'd-block';
            }
        }

        function handleServiceAmount() {
            if(application.database_type === 'none') {
                return "1x";
            } else {
                return "2x";
            }
        }

        function handlePriceAmount(size) {
            if(application.database_type === 'none') {
                if(size === 1){return '2,49 €'}
                if(size === 2){return '4,99 €'}
                if(size === 3){return '9,99 €'}
                if(size === 4){return '14,99 €'}
                if(size === 5){return '19,99 €'}
                if(size === 6){return '29,99 €'}
                if(size === 7){return '49,99 €'}
            } else {
                if(size === 1){return '4,99 €'}
                if(size === 2){return '9,99 €'}
                if(size === 3){return '19,99 €'}
                if(size === 4){return '29,99 €'}
                if(size === 5){return '39,99 €'}
                if(size === 6){return '59,99 €'}
                if(size === 7){return '99,99 €'}
            }
        }

        function handleApplicationBadgeColor(status){
            if(status === 'running') {
                return 'success';
            }
            if(status === 'pending') {
                return 'secondary';
            }
            if(status === 'failed') {
                return 'danger';
            }
            if(status === 'deleting') {
                return 'danger';
            }
            if(status === 'updating') {
                return 'warning';
            }
        }

        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <div
              style={{ cursor: 'pointer' }}
              ref={ref}
              onClick={e => {
                e.preventDefault();
                onClick(e);
              }}
            >
              {children}
              <FontAwesomeIcon style={{ fontSize: '16px', color: '#999999', paddingTop: '3px' }} icon={faCog} />
            </div>
          ));

          function handleApplicationLockStatus1(locked) {
            if(locked === true) {
                return <FontAwesomeIcon style={{ fontSize: '16px', color: '#999999', marginLeft: '12px', paddingBottom: '1.5px', opacity: '1' }} icon={faLock} />;
            }
            if(locked === false) {
                return <FontAwesomeIcon style={{ fontSize: '16px', color: '#999999', marginLeft: '12px', paddingBottom: '1.5px', opacity: '1' }} icon={faLockOpen} />;
            }
          }

          function appliationLocked(locked,status) {
            if(locked === true) {return 'disabled';}
            if(locked === false && status === 'updating') {return 'disabled';}
            else {return '';}
          }

          function appliationLockedStatus(status) {
            if(status === 'updating') {return 'disabled';}
            else {return '';}
          }

          function handleSshCopyString(url, port, username){
            let prefix = 'ssh ';
            let portString = ' -p ' + port;
            let usernameString = username + '@';
            let sshString = prefix + usernameString + url + portString
            return sshString
          }

        const applicationDropDrownMenu = 
            <Dropdown drop="left">
                <Dropdown.Toggle id="application-dropdown-toggle" as={CustomToggle}>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item disabled={appliationLocked(application.locked,application.status)} onClick={() => {
                        this.setState({ showSSLModal: true })
                    }}>SSL</Dropdown.Item>
                    <Dropdown.Item disabled={appliationLockedStatus(application.status)} onClick={() => {
                        this.setState({ showLockModal: true })
                    }}>Schutz</Dropdown.Item>
                    <Dropdown.Item disabled={appliationLocked(application.locked,application.status)} onClick={() => {
                        this.setState({ showTransferModal: true })
                    }}>Transfer</Dropdown.Item>
                    <Dropdown.Item disabled={appliationLocked(application.locked,application.status)} onClick={() => {
                        this.setState({ showRestartModal: true })
                    }}>Neustart</Dropdown.Item>
                    <Dropdown.Item disabled={appliationLocked(application.locked,application.status)} onClick={() => {
                        this.setState({ showRemoveModal: true })
                    }}>Entfernen</Dropdown.Item>
                    <Dropdown.Item disabled={appliationLockedStatus(application.status)} onClick={() => {
                        this.setState({ showRenameModal: true })
                    }}>Umbenennen</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        ;

        return (
            <div>
                <Row>
                    <Col key={application.id} xs={12} lg={handleDBCol(application.database_type)} style={{ marginBottom: '20px' }}>
                        <Paper elevation={0} className="card-body">
                            <div className="d-flex flex-row align-items-center" style={{ marginBottom: '5.5px' }}>
                                <div>
                                    <Typography className="application-view-title" variant="span">
                                        { application.name }
                                        {handleApplicationLockStatus1(application.locked)}
                                    </Typography>
                                </div>
                                <div className="ml-auto d-none d-md-block">
                                    <Badge pill className="sc-pill" variant={handleApplicationBadgeColor(application.status)}>
                                        { application.status }
                                    </Badge>
                                </div>
                                <div className="d-none d-md-block" style={{ marginLeft: '10px' }}>
                                {applicationDropDrownMenu}
                                </div>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <div style={{ marginRight: '10px'}}>
                                    <a style={{ marginRight: '10px' }} rel="noopener noreferrer" target="_blank" href={ "http://" + application.url } className="application-view-url">
                                    { application.url }
                                    </a>

                                    <CopyToClipboard text={handleSshCopyString(application.url, application.ssh_port, application.ssh_username)}
                                        onCopy={() => this.setState({copySSHCopied: true})}>
                                        <span>
                                            <FontAwesomeIcon title={"SSH Verbindungsdaten"} style={{ borderRadius: '5px', padding: '3px 3px 4px 3px', backgroundColor: '#eeeeee', cursor: 'pointer', fontSize: '15.5px', color: '#505050', opacity: '1' }} icon={faTerminal}/>
                                        </span>
                                    </CopyToClipboard>

                                </div>

                                {this.state.copySSHCopied ? <span style={{color: 'green', fontSize: '10px'}}>Kopiert!</span> : null}

                                <div className="ml-auto d-none d-md-block">
                                    <Typography variant="span" className="application-view-resources">
                                    { handleServiceAmount() + " " + application.cpu_limit + " vCPU + " + application.memory_limit }
                                    </Typography>
                                </div>
                            </div>
                            <div className="d-flex flex-row d-block d-md-none" style={{ marginTop: '6px' }}>
                            <div className="d-block d-md-none">
                                <Typography variant="span" className="application-view-resources">
                                { handleServiceAmount() + " " + application.cpu_limit + " vCPU + " + application.memory_limit }
                                </Typography>
                            </div>
                            <div className="ml-auto d-block d-md-none">
                                    <Badge pill className="sc-pill" variant={handleApplicationBadgeColor(application.status)}>
                                        { application.status }
                                    </Badge>
                                </div>
                                <div className="d-block d-md-none" style={{ marginLeft: '10px' }}>
                                {applicationDropDrownMenu}
                                </div>
                            </div>
                        </Paper>
                    </Col>
                    <Col xs={12} lg={6} style={{ marginBottom: '20px' }} className={handleDBColVis(application.database_type)}>
                        <DatabaseView application={this.state.application} />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} style={{ marginBottom: '20px' }}>
                        <Paper elevation={0} className="card-body" style={{ paddingBottom: '0px' }}>
                            <Row>
                            <Col className="mb-3" xs={12}>
                            <div className="d-flex flex-row">
                                <div>
                                <Typography variant="h6">Performance</Typography>
                                </div>
                                <div className="ml-auto">
                                    <Button variant="contained" color="primary" size="small" className="sc-save-button" disabled={appliationLocked(application.locked,application.status)} onClick={() => {

                                    const authToken = localStorage.getItem("authToken");

                                    fetch(`https://scapi.schnelle.cloud/sc/items/applications/${application.id}`, {
                                        method: 'PATCH',
                                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                        body: JSON.stringify({
                                            size: this.state.sizeselected,
                                            status: "updating"
                                        })
                                    })
                                    .then(data => data.json())
                                    .then(data => {
                                        if(data.data) {
                                            const authToken = localStorage.getItem("authToken");
                                            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.match.params.id}`, {
                                                method: 'GET',
                                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                            })
                                            .then(data => data.json())
                                            .then(data => {
                                                if(data.error) {
                                                } else {
                                                    this.setState({application: data.data})
                                                    this.setState({loading: false})
                                                    this.setState({sizeselected: this.state.application.size})
                                                    if(this.state.application.size === 1){
                                                        this.setState({sizeselected1: true})
                                                    }
                                                    if(this.state.application.size === 2){
                                                        this.setState({sizeselected2: true})
                                                    }
                                                    if(this.state.application.size === 3){
                                                        this.setState({sizeselected3: true})
                                                    }
                                                    if(this.state.application.size === 4){
                                                        this.setState({sizeselected4: true})
                                                    }
                                                    if(this.state.application.size === 5){
                                                        this.setState({sizeselected5: true})
                                                    }
                                                    if(this.state.application.size === 6){
                                                        this.setState({sizeselected6: true})
                                                    }
                                                    if(this.state.application.size === 7){
                                                        this.setState({sizeselected7: true})
                                                    }
                                                    this.setState({ successAlert: true })
                                                }
                                            });
                                        } else {
                                            this.setState({ errorAlert: true })
                                        }
                                    });
                                    }}>
                                    <FontAwesomeIcon style={{ fontSize: '16px', color: '#999999' }} icon={faSave} />
                                    </Button>
                                </div>
                            </div>
                            </Col>

                                <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                                    <Paper elevation={0} className="card-body sc-size-card">
                                        <div style={{ marginBottom: '10px' }}>
                                        <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected1} label={handlePriceAmount(1)} onClick={() =>
                                            {this.setState({ sizeselected1: true, sizeselected2: false, sizeselected3: false, sizeselected4: false, sizeselected5: false, sizeselected6: false, sizeselected7: false ,sizeselected: 1 })}
                                        } />
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 0.5 vCPU
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 512M RAM
                                        </div>
                                    </Paper>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                                    <Paper elevation={0} className="card-body sc-size-card">
                                        <div style={{ marginBottom: '10px' }}>
                                        <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected2} label={handlePriceAmount(2)} onClick={() =>
                                            {this.setState({ sizeselected1: false, sizeselected2: true, sizeselected3: false, sizeselected4: false, sizeselected5: false, sizeselected6: false, sizeselected7: false ,sizeselected: 2 })}
                                        } />
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 1 vCPU
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 1GB RAM
                                        </div>
                                    </Paper>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                                    <Paper elevation={0} className="card-body sc-size-card">
                                        <div style={{ marginBottom: '10px' }}>
                                        <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected3} label={handlePriceAmount(3)} onClick={() =>
                                            {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: true, sizeselected4: false, sizeselected5: false, sizeselected6: false, sizeselected7: false ,sizeselected: 3 })}
                                        } />
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 1 vCPU
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 2GB RAM
                                        </div>
                                    </Paper>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                                    <Paper elevation={0} className="card-body sc-size-card">
                                        <div style={{ marginBottom: '10px' }}>
                                        <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected4} label={handlePriceAmount(4)} onClick={() =>
                                            {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: false, sizeselected4: true, sizeselected5: false, sizeselected6: false, sizeselected7: false ,sizeselected: 4 })}
                                        } />
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 1 vCPU
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 4GB RAM
                                        </div>
                                    </Paper>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                                    <Paper elevation={0} className="card-body sc-size-card">
                                        <div style={{ marginBottom: '10px' }}>
                                        <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected5} label={handlePriceAmount(5)} onClick={() =>
                                            {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: false, sizeselected4: false, sizeselected5: true, sizeselected6: false, sizeselected7: false ,sizeselected: 5 })}
                                        } />
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 2 vCPU
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 4GB RAM
                                        </div>
                                    </Paper>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                                    <Paper elevation={0} className="card-body sc-size-card">
                                        <div style={{ marginBottom: '10px' }}>
                                        <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected6} label={handlePriceAmount(6)} onClick={() =>
                                            {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: false, sizeselected4: false, sizeselected5: false, sizeselected6: true, sizeselected7: false ,sizeselected: 6 })}
                                        } />
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 2 vCPU
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 8GB RAM
                                        </div>
                                    </Paper>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '20px' }}>
                                    <Paper elevation={0} className="card-body sc-size-card">
                                        <div style={{ marginBottom: '10px' }}>
                                        <Form.Check className="sc-size-label" type="checkbox" checked={this.state.sizeselected7} label={handlePriceAmount(7)} onClick={() =>
                                            {this.setState({ sizeselected1: false, sizeselected2: false, sizeselected3: false, sizeselected4: false, sizeselected5: false, sizeselected6: false, sizeselected7: true ,sizeselected: 7 })}
                                        } />
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 4 vCPU
                                        </div>
                                        <div className="sc-size-info">
                                            {handleServiceAmount()} 8GB RAM
                                        </div>
                                    </Paper>
                                </Col>
                            </Row>
                        </Paper>
                    </Col>
                </Row>

            <ApplicationSafety applicationisLocked={this.state.application.locked} application={this.state.application} />

                <Row>
                    <Col xs={12} lg={12} style={{ marginBottom: '20px' }}>
                        <DomainsView application={this.state.application} />
                    </Col>
                </Row>

                <SweetAlert
                show={this.state.successAlert}
                type='success'
                title="Anwendung angepasst"
                text="Deine Anwendung wird in kürze neu gestartet."
                onConfirm={() => this.setState({ successAlert: false })}
                />

                <SweetAlert
                show={this.state.showRemoveSuccessAlert}
                type='success'
                title="Erfolg"
                text="Deine Anwendung wird in kürze entfernt."
                onConfirm={() => this.setState({ showRemoveSuccessAlert: false })}
                />

                <SweetAlert
                show={this.state.showTransferSuccessAlert}
                type='success'
                title="Erfolg"
                text="Checke dein E-Mail Postfach und folge den Anweisungen."
                onConfirm={() => this.setState({ showTransferSuccessAlert: false })}
                />

                <SweetAlert
                show={this.state.errorAlert}
                type='error'
                title="Fehlgeschlagen"
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ errorAlert: false })}
                />

                <SweetAlert
                show={this.state.showApplicationError}
                type='error'
                title="Fehlgeschlagen"
                text="Die angefragte Anwendung wurde nicht gefunden."
                onConfirm={() => this.setState({ showApplicationError: false })}
                />

                <div>
                    <Modal show={this.state.showRemoveModal}>
                        <Modal.Header closeButton onClick={() => {this.setState({ showRemoveModal: false })}}>
                        <Modal.Title>Anwendung entfernen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ marginBottom: '10px' }}>Dieser Vorgang kann nicht Rückgängig gemacht werden.</div>
                            <div style={{ marginBottom: '30px' }}>Anwendung: <strong>{application.name}</strong></div>
                            <div style={{ marginBottom: '10px' }}>Vollständiger Name der zu entfernenden Anwendung:</div>
                            <TextField id="delete-application-name" fullWidth className="d-block" type="text" label="Name der Anwendung"/>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button id="cancel-remove-application-button" variant="secondary" onClick={() => {
                            this.setState({ showRemoveModal: false })
                        }}>
                            Abbrechen
                        </Button>
                        <Button style={{ marginLeft: '10px' }} variant="contained" color="secondary" onClick={() => {
                            let name = application.name;
                            let delete_name = document.getElementById("delete-application-name").value;

                            if(name === delete_name){
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/applications/${application.id}`, {
                                    method: 'PATCH',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        status: 'deleting'
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        this.setState({ showRemoveSuccessAlert: true })
                                        setTimeout(function(){ window.location.href = "/"; }, 2000);
                                    } else {
                                        this.setState({ errorAlert: true })
                                    }
                                });
                            } else {
                                this.setState({ errorAlert: true })
                            }

                        }}>
                            Entfernen
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <div>
                    <Modal show={this.state.showLockModal}>
                        <Modal.Header closeButton onClick={() => {this.setState({ showLockModal: false })}}>
                        <Modal.Title>Änderungsschutz</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ marginBottom: '10px' }}>
                                Aktiviere diese Option um deine Anwendung vor versehentlichen Änderungen über das Dashboard oder die API zu schützen.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button id="cancel-remove-application-button" variant="secondary" onClick={() => {
                            this.setState({ showLockModal: false })
                        }}>
                            Abbrechen
                        </Button>
                        <Button id="remove-application-button" className="primary" variant="primary" onClick={() => {
                            let locked = application.locked;

                            if(locked === true){
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/applications/${application.id}`, {
                                    method: 'PATCH',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        locked: false
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        this.setState({ showLockModal: false })
                                        const authToken = localStorage.getItem("authToken");
                                            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.match.params.id}`, {
                                                method: 'GET',
                                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                            })
                                            .then(data => data.json())
                                            .then(data => {
                                                if(data.error) {
                                                } else {
                                                    this.setState({application: data.data})
                                                    this.setState({loading: false})
                                                    this.setState({sizeselected: this.state.application.size})
                                                    if(this.state.application.size === 1){
                                                        this.setState({sizeselected1: true})
                                                    }
                                                    if(this.state.application.size === 2){
                                                        this.setState({sizeselected2: true})
                                                    }
                                                    if(this.state.application.size === 3){
                                                        this.setState({sizeselected3: true})
                                                    }
                                                    if(this.state.application.size === 4){
                                                        this.setState({sizeselected4: true})
                                                    }
                                                    if(this.state.application.size === 5){
                                                        this.setState({sizeselected5: true})
                                                    }
                                                    if(this.state.application.size === 6){
                                                        this.setState({sizeselected6: true})
                                                    }
                                                    if(this.state.application.size === 7){
                                                        this.setState({sizeselected7: true})
                                                    }
                                                }
                                            });
                                    } else {
                                        this.setState({ showLockModal: true })
                                    }
                                });
                            }

                            if(locked === false){
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/applications/${application.id}`, {
                                    method: 'PATCH',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        locked: true
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        this.setState({ showLockModal: false })
                                        const authToken = localStorage.getItem("authToken");
                                            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.match.params.id}`, {
                                                method: 'GET',
                                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                            })
                                            .then(data => data.json())
                                            .then(data => {
                                                if(data.error) {
                                                } else {
                                                    this.setState({application: data.data})
                                                    this.setState({loading: false})
                                                    this.setState({sizeselected: this.state.application.size})
                                                    if(this.state.application.size === 1){
                                                        this.setState({sizeselected1: true})
                                                    }
                                                    if(this.state.application.size === 2){
                                                        this.setState({sizeselected2: true})
                                                    }
                                                    if(this.state.application.size === 3){
                                                        this.setState({sizeselected3: true})
                                                    }
                                                    if(this.state.application.size === 4){
                                                        this.setState({sizeselected4: true})
                                                    }
                                                    if(this.state.application.size === 5){
                                                        this.setState({sizeselected5: true})
                                                    }
                                                    if(this.state.application.size === 6){
                                                        this.setState({sizeselected6: true})
                                                    }
                                                    if(this.state.application.size === 7){
                                                        this.setState({sizeselected7: true})
                                                    }
                                                }
                                            });
                                    } else {
                                        this.setState({ showLockModal: true })
                                    }
                                });
                            }
                            
                        }}>
                            {application.locked === false ? ("Aktivieren") : ("Deaktivieren")}
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <div>
                    <Modal show={this.state.showRenameModal}>
                        <Modal.Header closeButton onClick={() => {this.setState({ showRenameModal: false })}}>
                        <Modal.Title>Anwendung umbenennen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ marginBottom: '30px' }}>Anwendung: <strong>{application.name}</strong></div>
                            <TextField id="rename-application-name" fullWidth className="d-block" type="text" label="Neuer Name"/>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button id="cancel-remove-application-button" variant="secondary" onClick={() => {
                            this.setState({ showRenameModal: false })
                        }}>
                            Abbrechen
                        </Button>
                        <Button id="remove-application-button" className="primary" variant="primary" onClick={() => {

                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/applications/${application.id}`, {
                                    method: 'PATCH',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        name: document.getElementById("rename-application-name").value
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        const authToken = localStorage.getItem("authToken");
                                            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.match.params.id}`, {
                                                method: 'GET',
                                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                            })
                                            .then(data => data.json())
                                            .then(data => {
                                                if(data.error) {
                                                } else {
                                                    this.setState({application: data.data})
                                                    this.setState({loading: false})
                                                    this.setState({sizeselected: this.state.application.size})
                                                    if(this.state.application.size === 1){
                                                        this.setState({sizeselected1: true})
                                                    }
                                                    if(this.state.application.size === 2){
                                                        this.setState({sizeselected2: true})
                                                    }
                                                    if(this.state.application.size === 3){
                                                        this.setState({sizeselected3: true})
                                                    }
                                                    if(this.state.application.size === 4){
                                                        this.setState({sizeselected4: true})
                                                    }
                                                    if(this.state.application.size === 5){
                                                        this.setState({sizeselected5: true})
                                                    }
                                                    if(this.state.application.size === 6){
                                                        this.setState({sizeselected6: true})
                                                    }
                                                    if(this.state.application.size === 7){
                                                        this.setState({sizeselected7: true})
                                                    }
                                                    this.setState({ showRenameModal: false })
                                                    this.setState({ successAlert: true })
                                                }
                                            });
                                    } else {
                                        this.setState({ errorAlert: true })
                                    }
                                });

                        }}>
                            Umbenennen
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <div>
                    <Modal show={this.state.showTransferModal}>
                        <Modal.Header closeButton onClick={() => {this.setState({ showTransferModal: false })}}>
                        <Modal.Title>Transfer starten</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ marginBottom: '30px' }}>Transfer zu einem anderen Kundenkonto einleiten.</div>
                            <div style={{ marginBottom: '30px' }}>Anwendung: <strong>{application.name}</strong></div>
                            <div style={{ marginBottom: '10px' }}>Vollständiger Name der Anwendung:</div>
                            <TextField id="tarnsfer-application-name" fullWidth className="d-block" type="text" label="Name der Anwendung"/>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button id="cancel-remove-application-button" variant="secondary" onClick={() => {
                            this.setState({ showTransferModal: false })
                        }}>
                            Abbrechen
                        </Button>
                        <Button id="remove-application-button" className="primary" variant="primary" onClick={() => {
                            let name = application.name;
                            let transfer_name = document.getElementById("tarnsfer-application-name").value;

                            if(name === transfer_name){
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/transfer`, {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        status: 'pending',
                                        application_id: this.state.application.id
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        this.setState({ showTransferSuccessAlert: true })
                                        setTimeout(function(){ window.location.href = "/account"; }, 3000);
                                    } else {
                                        this.setState({ errorAlert: true })
                                    }
                                });
                            } else {
                                this.setState({ errorAlert: true })
                            }

                        }}>
                            Starten
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <div>
                    <Modal show={this.state.showSSLModal}>
                        <Modal.Header closeButton onClick={() => {this.setState({ showSSLModal: false })}}>
                        <Modal.Title>Let's Encrypt</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ marginBottom: '10px' }}>
                                Aktiviere diese Option nur wenn deine Anwendung bestehen soll um dein Limit an SSL-Zertifikaten zu schonen.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button id="cancel-remove-application-button" variant="secondary" onClick={() => {
                            this.setState({ showSSLModal: false })
                        }}>
                            Abbrechen
                        </Button>
                        <Button id="remove-application-button" className="primary" variant="primary" onClick={() => {
                            let ssl = application.ssl;

                            if(ssl === true){
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/applications/${application.id}`, {
                                    method: 'PATCH',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        ssl: false,
                                        status: 'updating'
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        this.setState({ showSSLModal: false })
                                        const authToken = localStorage.getItem("authToken");
                                            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.match.params.id}`, {
                                                method: 'GET',
                                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                            })
                                            .then(data => data.json())
                                            .then(data => {
                                                if(data.error) {
                                                } else {
                                                    this.setState({application: data.data})
                                                    this.setState({loading: false})
                                                    this.setState({sizeselected: this.state.application.size})
                                                    if(this.state.application.size === 1){
                                                        this.setState({sizeselected1: true})
                                                    }
                                                    if(this.state.application.size === 2){
                                                        this.setState({sizeselected2: true})
                                                    }
                                                    if(this.state.application.size === 3){
                                                        this.setState({sizeselected3: true})
                                                    }
                                                    if(this.state.application.size === 4){
                                                        this.setState({sizeselected4: true})
                                                    }
                                                    if(this.state.application.size === 5){
                                                        this.setState({sizeselected5: true})
                                                    }
                                                    if(this.state.application.size === 6){
                                                        this.setState({sizeselected6: true})
                                                    }
                                                    if(this.state.application.size === 7){
                                                        this.setState({sizeselected7: true})
                                                    }
                                                    this.setState({ successAlert: true })
                                                }
                                            });
                                    } else {
                                        this.setState({ showSSLModal: true })
                                    }
                                });
                            }

                            if(ssl === false){
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/applications/${application.id}`, {
                                    method: 'PATCH',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        ssl: true,
                                        status: 'updating'
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        this.setState({ showSSLModal: false })
                                        const authToken = localStorage.getItem("authToken");
                                            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.match.params.id}`, {
                                                method: 'GET',
                                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                            })
                                            .then(data => data.json())
                                            .then(data => {
                                                if(data.error) {
                                                } else {
                                                    this.setState({application: data.data})
                                                    this.setState({loading: false})
                                                    this.setState({sizeselected: this.state.application.size})
                                                    if(this.state.application.size === 1){
                                                        this.setState({sizeselected1: true})
                                                    }
                                                    if(this.state.application.size === 2){
                                                        this.setState({sizeselected2: true})
                                                    }
                                                    if(this.state.application.size === 3){
                                                        this.setState({sizeselected3: true})
                                                    }
                                                    if(this.state.application.size === 4){
                                                        this.setState({sizeselected4: true})
                                                    }
                                                    if(this.state.application.size === 5){
                                                        this.setState({sizeselected5: true})
                                                    }
                                                    if(this.state.application.size === 6){
                                                        this.setState({sizeselected6: true})
                                                    }
                                                    if(this.state.application.size === 7){
                                                        this.setState({sizeselected7: true})
                                                    }
                                                    this.setState({ successAlert: true })
                                                }
                                            });
                                    } else {
                                        this.setState({ showSSLModal: true })
                                    }
                                });
                            }
                            
                        }}>
                            {application.ssl === false ? ("Aktivieren") : ("Deaktivieren")}
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>


                <div>
                    <Modal show={this.state.showRestartModal}>
                        <Modal.Header closeButton onClick={() => {this.setState({ showRestartModal: false })}}>
                        <Modal.Title>Anwendung neu starten</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ marginBottom: '0px' }}>
                                Achtung: Ihre Anwendung wird kurzzeitig nicht erreiechbar sein.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button id="cancel-remove-application-button" variant="secondary" onClick={() => {
                            this.setState({ showRestartModal: false })
                        }}>
                            Abbrechen
                        </Button>
                        <Button id="remove-application-button" className="primary" variant="primary" onClick={() => {
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/applications/${application.id}`, {
                                    method: 'PATCH',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        status: 'updating'
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        this.setState({ showRestartModal: false })
                                        const authToken = localStorage.getItem("authToken");
                                            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.match.params.id}`, {
                                                method: 'GET',
                                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                            })
                                            .then(data => data.json())
                                            .then(data => {
                                                if(data.error) {
                                                } else {
                                                    this.setState({application: data.data})
                                                    this.setState({loading: false})
                                                    this.setState({sizeselected: this.state.application.size})
                                                    if(this.state.application.size === 1){
                                                        this.setState({sizeselected1: true})
                                                    }
                                                    if(this.state.application.size === 2){
                                                        this.setState({sizeselected2: true})
                                                    }
                                                    if(this.state.application.size === 3){
                                                        this.setState({sizeselected3: true})
                                                    }
                                                    if(this.state.application.size === 4){
                                                        this.setState({sizeselected4: true})
                                                    }
                                                    if(this.state.application.size === 5){
                                                        this.setState({sizeselected5: true})
                                                    }
                                                    if(this.state.application.size === 6){
                                                        this.setState({sizeselected6: true})
                                                    }
                                                    if(this.state.application.size === 7){
                                                        this.setState({sizeselected7: true})
                                                    }
                                                    this.setState({ successAlert: true })
                                                }
                                            });
                                    } else {
                                        this.setState({ showRestartModal: true })
                                    }
                                });

                        }}>
                            Neustart
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

            </div>
        )
    }
}

export default ApplicationSingle
