import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Dashboard from './components/pages/Dashboard';
import Sign from './components/pages/Sign';
import Navigation from './components/navigation/Navigation';
import Marketplace from './components/pages/Marketplace';
import Account from './components/pages/Account';
import Deploy from './components/pages/Deploy';
import ApplicationSingle from './components/pages/ApplicationSingle';
import InvoiceSingle from './components/pages/InvoiceSingle';
import './App.css';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route 
  {...rest}
  render={props =>
    localStorage.getItem("authToken") ? (
      <Component {...props} />
    ) : (
      <Redirect 
      to={{
        pathname: "/login",
        state: { from: props.location }
      }}
      />
    )
  }
  />
);

export class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedin: 0
    }
  }

  render() {
    return (
      <div id="main-wrapper">
        <Navigation />
        <Container id="main-content">
          <Switch>
            <Route exact path="/login" component={Sign} />
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/marketplace" component={Marketplace} />
            <PrivateRoute exact path="/account" component={Account} />
            <PrivateRoute exact path="/marketplace/:id" component={Deploy} />
            <PrivateRoute exact path="/application/:id" component={ApplicationSingle} />
            <PrivateRoute exact path="/invoice/:id" component={InvoiceSingle} />
          </Switch>
        </Container>
      </div>
    );
  }
}

export default App;
