import React, { Component } from 'react'
import { Table, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Paper, Typography } from '@material-ui/core';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export class Invoices extends Component {


    constructor(props) {
        super(props);
        this.state = {
            rechnungen: null
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/items/rechnungen?sort=-id', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({rechnungen: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch('https://scapi.schnelle.cloud/sc/items/rechnungen?sort=-id', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({rechnungen: data.data})
                this.setState({loading: false})
            }
        });
          }, 60000);
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.rechnungen) {return <Loading />}

        function handleStatusColor(status) {
            if(status === 'unpaid'){
                return 'warning';
            }
            if(status === 'paid'){
                return 'success';
            }
            if(status === 'overdue'){
                return 'danger';
            }
            if(status === 'canceled'){
                return 'secondary';
            }
        }

        function translateStatusName(status) {
            if(status === 'unpaid'){
                return 'unbezahlt';
            }
            if(status === 'paid'){
                return 'bezahlt';
            }
            if(status === 'canceled'){
                return 'storniert';
            }
            if(status === 'overdue'){
                return 'überfällig';
            }
        }

        let invoice_sum = 0

        function handleInvoiceSum(amount) {
            invoice_sum = Number(invoice_sum) + Number(amount)
            invoice_sum = invoice_sum.toFixed(2)
        }

        const rechnungen = this.state.rechnungen.map((rechnung, key) =>
            <tr key={key}>
                <td>{rechnung.id}</td>
                <td>{rechnung.invoice_date}</td>
                <td>{rechnung.due_date}</td>
                <td>{rechnung.net_amount} €</td>
                <td>{rechnung.gross_amount} €</td>
                <td>
                <Badge pill className="sc-pill-mini" variant={handleStatusColor(rechnung.status)}>
                    {translateStatusName(rechnung.status)}
                </Badge>
                <Link title="Rechnung anzeigen" to={"/invoice/" + rechnung.id}><FontAwesomeIcon style={{ fontSize: '17px', color: '#6c757d', paddingTop: '5px', marginLeft: '5px' }} icon={faEye} /></Link>
                { handleInvoiceSum(rechnung.gross_amount)}
                </td>
            </tr>
        );

        return (
            <Paper className="card-body" elevation={0} style={{paddingBottom: '0px'}}>
                <Typography variant="h6">
                <div className="d-flex flex-row">
                        <div>Rechnungen</div>
                        <div style={{ color: '#505050', marginLeft: 10 }}>({invoice_sum} €)</div>
                    </div>
                </Typography>
                <Table style={{ marginTop: '20px' }} responsive>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Datum</th>
                        <th>Fälligkeit</th>
                        <th>Summe (Netto)</th>
                        <th>Summe (Brutto)</th>
                        <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {rechnungen}
                    </tbody>
                </Table>
            </Paper>
        )
    }
}

export default Invoices
