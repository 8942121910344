import React, { Component } from 'react'
import { Table, Badge } from 'react-bootstrap';
import { Paper, Typography } from '@material-ui/core';
import Loading from '../components/Loading';

export class BillingLog extends Component {


    constructor(props) {
        super(props);
        this.state = {
            logs: null
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/items/billing_statement?sort=-id&limit=2000', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({logs: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch('https://scapi.schnelle.cloud/sc/items/billing_statement?sort=-id&limit=2000', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({logs: data.data})
                this.setState({loading: false})
            }
        });
          }, 60000);
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.logs) {return <Loading />}

        function handleStatusColor(status) {
            if(status === 'published'){
                return 'success';
            }
        }

        let billingstatement_sum = 0

        function handleBillingStatementSum(amount) {
            billingstatement_sum = Number(billingstatement_sum) + Number(amount)
            billingstatement_sum = billingstatement_sum.toFixed(2)
        }

        function handleStatusName(status) {
            if(status === 'published'){
                return 'offen';
            }
        }

        const logs = this.state.logs.map((log, key) =>
            <tr key={key}>
                <td>{log.created_on}</td>
                <td>{log.type}</td>
                <td>{log.item_id}</td>
                <td>{log.total_minutes}</td>
                <td>{log.total_costs}</td>
                <td>
                <Badge pill className="sc-pill-mini" variant={handleStatusColor(log.status)}>
                {handleStatusName(log.status)}
                </Badge>
                { handleBillingStatementSum(log.total_costs)}
                </td>
            </tr>
        );

        return (
            <Paper className="card-body" elevation={0} style={{paddingBottom: '0px'}}>
                <Typography variant="h6">
                    <div className="d-flex flex-row">
                        <div>Kostenübersicht</div>
                        <div style={{ color: '#505050', marginLeft: 10 }}>({billingstatement_sum} €)</div>
                    </div>
                </Typography>
                <Table style={{ marginTop: '20px' }} responsive>
                    <thead>
                        <tr>
                        <th>Zeitstempel</th>
                        <th>Beschreibung</th>
                        <th>Item ID</th>
                        <th>Minuten</th>
                        <th>Summe in €</th>
                        <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {logs}
                    </tbody>
                </Table>
            </Paper>
        )
    }
}

export default BillingLog
