import React, { Component } from 'react';
import { TextField, Paper, Typography, Button } from '@material-ui/core';
import { Badge, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCog } from '@fortawesome/free-solid-svg-icons';
import SweetAlert from 'sweetalert2-react';
import Loading from '../Loading';

export class DomainsView extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            domains: {},
            showError: false
        }
    }

    componentDidMount() {
        const authToken = localStorage.getItem("authToken");
        fetch(`https://scapi.schnelle.cloud/sc/items/domains?filter[application_id]=${this.props.application.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({domains: data.data, loading: false})
            }
        });
        setInterval(() => {
            fetch(`https://scapi.schnelle.cloud/sc/items/domains?filter[application_id]=${this.props.application.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({domains: data.data, loading: false})
            }
        });
          }, 30000);
    }


    render() {

        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <div
              style={{ cursor: 'pointer' }}
              ref={ref}
              onClick={e => {
                e.preventDefault();
                onClick(e);
              }}
            >
              {children}
              <FontAwesomeIcon style={{ fontSize: '16px', color: '#999999', paddingTop: '3px' }} icon={faCog} />
            </div>
          ));

        if(this.state.loading) {return <Loading />}

        if(!this.state.domains) {return <Loading />}

        const application = this.props.application;

        function appliationLocked(locked,status) {
            if(locked === true) {return 'disabled';}
            if(locked === false && status === 'updating') {return 'disabled';}
            else {return '';}
          }

        function domainAttached(status) {
            if(status === 'success') {return 'd-none'}
            if(status !== 'success') {return 'd-block'}
        }

          function handleDomainBadgeColor(status){
            if(status === 'open') {
                return 'secondary';
            }
            if(status === 'pending') {
                return 'warning';
            }
            if(status === 'failed') {
                return 'danger';
            }
            if(status === 'deleting') {
                return 'warning';
            }
            if(status === 'verify') {
                return 'warning';
            }
            if(status === 'verified') {
                return 'primary';
            }
            if(status === 'success') {
                return 'success';
            }
        }

        const domains = this.state.domains.map((domain, key) =>
            <div className="d-flex flex-row" key={domain.id} style={{ marginBottom: '10px' }}>
                <div>
                <Typography variant="span">
                    <a rel="noopener noreferrer" style={{ fontSize: '15px' }} target="_blank" href={"http://" + domain.name}>{domain.name}</a>
                </Typography>
                </div>
                
                <div className="ml-auto">
                <Badge pill className="sc-pill-mini" variant={handleDomainBadgeColor(domain.status)}>
                    { domain.status }
                </Badge>
                </div>
                <div className="ml-2">
                <Dropdown drop="left">
                    <Dropdown.Toggle style={{caret: 'none'}} as={CustomToggle} id="dropdown-basic">
                        {/* <FontAwesomeIcon style={{ fontSize: '14px', color: '#0088C8' }} icon={faCog} /> */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item className={domainAttached(domain.status)} disabled={appliationLocked(application.locked,application.status)} onClick={() => {
                            const authToken = localStorage.getItem("authToken");
                            fetch(`https://scapi.schnelle.cloud/sc/items/domains/${domain.id}`, {
                                method: 'PATCH',
                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                body: JSON.stringify({
                                    status: "verify"
                                })
                            })
                            .then(data => data.json())
                            .then(data => {
                                if(data.data) {
                                    const authToken = localStorage.getItem("authToken");
                                    fetch(`https://scapi.schnelle.cloud/sc/items/domains?filter[application_id]=${this.props.application.id}`, {
                                        method: 'GET',
                                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    })
                                    .then(data => data.json())
                                    .then(data => {
                                        if(data.error) {
                                            localStorage.removeItem("authToken")
                                            window.location = "/"
                                        } else {
                                            this.setState({domains: data.data, loading: false})
                                            this.setState({ showSuccess: true })
                                        }
                                    });
                                } else {
                                    this.setState({ showError: true })
                                }
                            });
                        }}>Verifizieren</Dropdown.Item>
                        <Dropdown.Item disabled={appliationLocked(application.locked,application.status)} onClick={() => {
                            const authToken = localStorage.getItem("authToken");
                            fetch(`https://scapi.schnelle.cloud/sc/items/domains/${domain.id}`, {
                                method: 'PATCH',
                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                body: JSON.stringify({
                                    status: "deleting"
                                })
                            })
                            .then(data => data.json())
                            .then(data => {
                                if(data.data) {
                                    const authToken = localStorage.getItem("authToken");
                                    fetch(`https://scapi.schnelle.cloud/sc/items/domains?filter[application_id]=${this.props.application.id}`, {
                                        method: 'GET',
                                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    })
                                    .then(data => data.json())
                                    .then(data => {
                                        if(data.error) {
                                            localStorage.removeItem("authToken")
                                            window.location = "/"
                                        } else {
                                            this.setState({domains: data.data, loading: false})
                                            this.setState({ showSuccess: true })
                                        }
                                    });
                                } else {
                                    this.setState({ showError: true })
                                }
                            });
                        }}>Entfernen</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            </div>
        );

        return (
            <div>
                <Paper elevation={0} className="card-body" style={{ paddingBottom: '10px' }}>
                    <div className="d-flex flex-row">
                    <div style={{ marginBottom: '10px' }}>
                        <Typography variant="h6">
                            Domains
                        </Typography>
                    </div>
                    <div className="ml-auto">
                    <FontAwesomeIcon disabled={appliationLocked(application.locked,application.status)} style={{ fontSize: '14px', color: '#999999', cursor: 'pointer' }} icon={faPlus} onClick={() =>{
                        this.setState({ showAddDomainModal: true })
                    }}/>
                    </div>
                    </div>
                    <div>
                        {domains}
                    </div>
                </Paper>

                <div>
                    <Modal show={this.state.showAddDomainModal}>
                        <Modal.Header closeButton onClick={() => {this.setState({ showAddDomainModal: false })}}>
                        <Modal.Title>Domain verknüpfen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ marginBottom: '10px' }}>Verknüpfen Sie eine Domain die Sie bereits besitzen:</div>
                        <TextField id="add-domain-name" fullWidth className="d-block" type="text" placeholder="z.B. blog.meinedomain.de" label="Domain"/>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button id="cancel-add-domain-button" variant="secondary" onClick={() => {
                            this.setState({ showAddDomainModal: false })
                        }}>
                            Abbrechen
                        </Button>
                        <Button disabled={appliationLocked(application.locked,application.status)} id="add-domain-button" className="primary" variant="primary" onClick={() => {

                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/domains`, {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        name: document.getElementById("add-domain-name").value,
                                        application_id: this.props.application.id,
                                        status: "open"
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        this.setState({ showAddDomainModal: false })
                                        const authToken = localStorage.getItem("authToken");
                                        fetch(`https://scapi.schnelle.cloud/sc/items/domains?filter[application_id]=${this.props.application.id}`, {
                                            method: 'GET',
                                            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                        })
                                        .then(data => data.json())
                                        .then(data => {
                                            if(data.error) {
                                                localStorage.removeItem("authToken")
                                                window.location = "/"
                                            } else {
                                                this.setState({domains: data.data, loading: false})
                                                this.setState({ showSuccess: true })
                                            }
                                        });
                                    } else {
                                        this.setState({ showAddDomainModal: true })
                                    }
                                });
                            
                        }}>
                            Vorgang starten
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <SweetAlert
                show={this.state.showError}
                type="error"
                title="Fehlgeschlagen"
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ showError: false })}
                />

                <SweetAlert
                show={this.state.showSuccess}
                type="success"
                title="Anfrage erfolgreich"
                text="Domain verknüpfung erfolgreich gestartet."
                onConfirm={() => this.setState({ showSuccess: false })}
                />
            </div>
        )
    }
}

export default DomainsView
