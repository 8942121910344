import React, { Component } from 'react'
import { Table, Badge } from 'react-bootstrap';
import { Paper, Typography } from '@material-ui/core';
import Loading from '../components/Loading';

export class Credits extends Component {

    constructor(props) {
        super(props);
        this.state = {
            credits: null
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/items/credits?sort=-id', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({credits: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch('https://scapi.schnelle.cloud/sc/items/credits?sort=-id', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            })
            .then(data => data.json())
            .then(data => {
                if(data.error) {
                    localStorage.removeItem("authToken")
                    window.location = "/"
                } else {
                    this.setState({credits: data.data})
                    this.setState({loading: false})
                }
            });
          }, 60000);
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.credits) {return <Loading />}

        let credit_sum = 0

        function handleCreditSum(amount) {
            credit_sum = Number(credit_sum) + Number(amount)
            credit_sum = credit_sum.toFixed(2)
        }

        function handleCreditStatus(status) {
            if(status === 'success'){
                return 'erfolgreich';
            }
        }

        const credits = this.state.credits.map((credit, key) =>
            <tr key={"test"}>
                <td>{credit.reference}</td>
                <td>{credit.date}</td>
                <td>{credit.gross_amount} €</td>
                <td>
                <Badge variant="success" pill className="sc-pill-mini">
                    {handleCreditStatus(credit.status)}
                </Badge>
                </td>
                { handleCreditSum(credit.gross_amount)}
            </tr>
        );

        return (
            <Paper className="card-body" elevation={0} style={{paddingBottom: '0px'}}>
                <Typography variant="h6">
                    <div className="d-flex flex-row">
                        <div>Guthaben</div>
                        <div style={{ color: '#505050', marginLeft: 10 }}>({credit_sum} €)</div>
                    </div>
                </Typography>
                <Table style={{ marginTop: '20px' }} responsive>
                    <thead>
                        <tr>
                        <th>Referenz</th>
                        <th>Datum</th>
                        <th>Summe</th>
                        <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {credits}
                    </tbody>
                </Table>
            </Paper>
        )
    }
}

export default Credits
