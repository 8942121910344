import React, { Component } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Row, Col, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ResourceCount from './ResourceCount'
import Loading from '../Loading';
import CircularProgress from '@material-ui/core/CircularProgress';

export class ApplicationsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            applications: null
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/items/applications?fields=id,name,url,status,cpu_limit,memory_limit,database_type', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({applications: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch('https://scapi.schnelle.cloud/sc/items/applications?fields=id,name,url,status,cpu_limit,memory_limit,database_type', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({applications: data.data})
                this.setState({loading: false})
            }
        });
          }, 30000);
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.applications) {return <Loading />}

        function handleServiceAmount(type) {
            if(type === 'none') {
                return "1x ";
            } else {
                return "2x ";
            }
        }

        function handleApplicationBadgeColor(status){
            if(status === 'running') {
                return 'success';
            }
            if(status === 'pending') {
                return 'secondary';
            }
            if(status === 'failed') {
                return 'danger';
            }
            if(status === 'deleting') {
                return 'danger';
            }
            if(status === 'updating') {
                return 'warning';
            }
        }

        function handleLink(status, id){
            if(status === 'pending'){
                return '';
            }
            else {
                return "/application/" + id;
            }
        }

        function handleDisabled(status){
            if(status === 'pending'){
                return 'disabled';
            }
            else {
                return '';
            }
        }

        const applications = this.state.applications.map((application, key) =>
            <Col key={application.id} xs={12} lg={6} style={{ marginBottom: '20px' }}>
                <Link className={handleDisabled(application.status)}  to={handleLink(application.status, application.id)}>
                    <Paper elevation={0} className="card-body">
                        <div className="d-flex flex-row">
                            <div style={{ marginBottom: '5px' }}>
                                <Typography className="application-view-title" variant="span">
                                    { application.name }
                                </Typography>
                            </div>
                            <div className="ml-auto d-none d-md-block">
                                <Badge pill className="sc-pill" variant={handleApplicationBadgeColor(application.status)}>{ application.status }</Badge>
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div>
                                <a rel="noopener noreferrer" target="_blank" href={ "http://" + application.url } className="application-view-url">
                                    { application.url ? (application.url) : (<CircularProgress size={17} style={{ color: '#505050'}} />) }
                                </a>
                            </div>
                            <div className="ml-auto d-none d-md-block">
                                <Typography variant="span" className="application-view-resources">
                                    { application.cpu_limit ? (handleServiceAmount(application.database_type)) : ('') }
                                    { application.cpu_limit ? (application.cpu_limit + " vCPU + " + application.memory_limit) : ('') }
                                </Typography>
                            </div>
                        </div>
                        <div className="d-flex flex-row d-block d-md-none" style={{ marginTop: '5px' }}>
                        <div className="d-block d-md-none">
                            <Typography variant="span" className="application-view-resources">
                            { application.cpu_limit ? (application.cpu_limit + " vCPU + " + application.memory_limit) : ('') }
                            </Typography>
                        </div>
                        <div className="ml-auto d-block d-md-none">
                            <Badge pill className="sc-pill" variant={handleApplicationBadgeColor(application.status)}>{ application.status }</Badge>
                        </div>
                        </div>
                    </Paper>
                </Link>
            </Col>
        );

        return (
            <div>
                <ResourceCount applications={this.state.applications} />
                <Row>
                    {applications}
                </Row>
            </div>
        )
    }
}

export default ApplicationsView
