import React, { Component } from 'react'
import { Table, Badge } from 'react-bootstrap';
import { Paper, Typography } from '@material-ui/core';
import Loading from '../components/Loading';

export class Transactions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transactions: null
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/items/transactions?sort=-id', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({transactions: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch('https://scapi.schnelle.cloud/sc/items/transactions?sort=-id', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            })
            .then(data => data.json())
            .then(data => {
                if(data.error) {
                    localStorage.removeItem("authToken")
                    window.location = "/"
                } else {
                    this.setState({transactions: data.data})
                    this.setState({loading: false})
                }
            });
          }, 60000);
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.transactions) {return <Loading />}

        let transaction_sum = 0

        function handleTransactionSum(amount) {
            transaction_sum = Number(transaction_sum) + Number(amount)
            transaction_sum = transaction_sum.toFixed(2)
        }

        const transactions = this.state.transactions.map((transaction, key) =>
            <tr key={"test"}>
                <td>{transaction.reference}</td>
                <td>{transaction.date}</td>
                <td>{transaction.amount} €</td>
                <td>
                    <Badge variant="success" pill className="sc-pill-mini">
                        {handleTransactionStatus(transaction.status)}
                    </Badge>
                { handleTransactionSum(transaction.amount)}
                </td>
            </tr>
        );

        function handleTransactionStatus(status) {
            if(status === 'success'){
                return 'erfolgreich';
            }
        }

        return (
            <Paper className="card-body" elevation={0} style={{paddingBottom: '0px'}}>
                <Typography variant="h6">
                    <div className="d-flex flex-row">
                        <div>Transaktionen</div>
                        <div style={{ color: '#505050', marginLeft: 10 }}>({transaction_sum} €)</div>
                    </div>
                </Typography>
                <Table style={{ marginTop: '20px' }} responsive>
                    <thead>
                        <tr>
                        <th>Referenz</th>
                        <th>Datum</th>
                        <th>Summe</th>
                        <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {transactions}
                    </tbody>
                </Table>
            </Paper>
        )
    }
}

export default Transactions
