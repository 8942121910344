import React, { Component } from 'react'
import { Row, Col, Badge, Table } from 'react-bootstrap';
import { TextField, Paper, Typography, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Loading from '../components/Loading';
import SweetAlert from 'sweetalert2-react';

export class Transfer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transfers: [],
            transfers_accept: []
        }
    }

    componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/items/transfer', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({transfers: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch('https://scapi.schnelle.cloud/sc/items/transfer', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({transfers: data.data})
                this.setState({loading: false})
            }
        });
          }, 60000);
        fetch('https://scapi.schnelle.cloud/sc/items/transfer_accept', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({transfers_accept: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch('https://scapi.schnelle.cloud/sc/items/transfer_accept', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({transfers_accept: data.data})
                this.setState({loading: false})
            }
        });
          }, 60000);
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.transfers) {return <Loading />}

        function handleTransferStatus(status) {
            if(status === 'success'){
                return 'erfolgreich';
            }
            if(status === 'ready'){
                return 'bereit';
            }
            if(status === 'failed'){
                return 'fehlgeschlagen';
            }
            if(status === 'pending'){
                return 'in bearbeitung';
            }
        }

        function handleStatus(status) {
            if(status === 'pending') { return 'secondary'; }
            if(status === 'ready') { return 'warning'; }
            if(status === 'success') { return 'success'; }
        }

        const transfers = this.state.transfers.map((transfer, key) =>
            <tr key={transfer.id}>
                <td>{transfer.application_id}</td>
                <td>{transfer.created_on}</td>
                <td>{transfer.code ? transfer.code : 'n.a.'}</td>
                <td>{transfer.verification ? transfer.verification : 'n.a.'}</td>
                <td className="align-items-center">
                <Badge pill className="sc-pill-mini" variant={handleStatus(transfer.status)}>
                    {handleTransferStatus(transfer.status)}
                </Badge>
                {transfer.status === 'ready' ? (<span className="remove-x" onClick={() => {
                    const authToken = localStorage.getItem("authToken");
                    fetch(`https://scapi.schnelle.cloud/sc/items/transfer/${transfer.id}`, {
                        method: 'DELETE',
                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                    })
                    .then(() => {
                        this.setState({ successAlertCancel: true })
                        setTimeout(function(){ window.location.reload() }, 3000);
                    });
                }}>x</span>) : ''}
                </td>
            </tr>
        );

        const transfers_accept = this.state.transfers_accept.map((transfer, key) =>
            <tr key={transfer.id}>
                <td>{transfer.application_id ? transfer.application_id : 'n.a.'}</td>
                <td>{transfer.created_on}</td>
                <td>{transfer.code ? transfer.code : 'n.a.'}</td>
                <td>{transfer.verification ? transfer.verification : 'n.a.'}</td>
                <td>
                <Badge pill className="sc-pill-mini" variant={handleStatus(transfer.status)}>
                    {handleTransferStatus(transfer.status)}
                </Badge>
                </td>
            </tr>
        );

        return (
            <div>
                <Paper className="card-body" elevation={0} style={{ paddingBottom: '0px' }}>
                    <Typography variant="h6">
                        Transfer Verlauf <FontAwesomeIcon title="outbound" style={{ fontSize: '18px', color: '#999999', marginLeft: '10px' }} icon={faArrowRight} />
                    </Typography>
                    <Table style={{ marginTop: '20px' }} responsive>
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Datum</th>
                            <th>Code</th>
                            <th>Verfifizierung</th>
                            <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {transfers}
                        </tbody>
                    </Table>
                </Paper>
                <Paper className="card-body" elevation={0} style={{ paddingBottom: '0px', marginTop: '20px' }}>
                    <Typography variant="h6">
                        Transfer Verlauf <FontAwesomeIcon title="inbound" style={{ fontSize: '18px', color: '#999999', marginLeft: '10px' }} icon={faArrowLeft} />
                    </Typography>
                    <Table style={{ marginTop: '20px' }} responsive>
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Datum</th>
                            <th>Code</th>
                            <th>Verfifizierung</th>
                            <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.transfers_accept ? transfers_accept: ''}
                        </tbody>
                    </Table>
                </Paper>
                <Paper className="card-body" elevation={0} style={{ paddingBottom: '20px', marginTop: '20px' }}>
                    <div className="d-flex flex-row">
                        <div>
                            <Typography variant="h6" style={{ marginBottom: '20px' }}>
                                Transfer Annahme
                            </Typography>
                        </div>
                        <div className="ml-auto d-none d-md-block">
                            <Button style={{ padding: '2px 10px' }} className="primary" variant="contained" color="primary" onClick={() => {
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/transfer_accept`, {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        status: 'pending',
                                        code: document.getElementById("transfer-code").value,
                                        verification: document.getElementById("transfer-verification").value
                                    })
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.data) {
                                        this.setState({ successAlert: true })
                                        setTimeout(function(){ window.location.reload() }, 3000);
                                    } else {
                                        this.setState({ errorAlert: true })
                                    }
                                });
                            }}>
                                Transfer
                            </Button>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <TextField id="transfer-code" fullWidth className="d-block" type="text" label="Code"/>
                        </Col>
                        <Col>
                            <TextField id="transfer-verification" fullWidth className="d-block" type="text" label="Verifizierung"/>
                        </Col>
                        
                    </Row>
                    <div className="d-block d-md-none" style={{ marginTop: '20px' }}>
                                <Button style={{ padding: '2px 10px' }} className="primary" id="secondary-btn" variant="primary" onClick={() => {
                                    const authToken = localStorage.getItem("authToken");
                                    fetch(`https://scapi.schnelle.cloud/sc/items/transfer_accept`, {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                        body: JSON.stringify({
                                            status: 'pending',
                                            code: document.getElementById("transfer-code").value,
                                            verification: document.getElementById("transfer-verification").value
                                        })
                                    })
                                    .then(data => data.json())
                                    .then(data => {
                                        if(data.data) {
                                            this.setState({ successAlert: true })
                                            setTimeout(function(){ window.location.reload() }, 3000);
                                        } else {
                                            this.setState({ errorAlert: true })
                                        }
                                    });
                                }}>
                                    Transfer
                                </Button>
                            </div>
                </Paper>

                <SweetAlert
                show={this.state.successAlert}
                title="Anfrage erfolgreich"
                type='success'
                text="Der Transfer wird in kürze gestartet."
                onConfirm={() => this.setState({ successAlert: false })}
                />

                <SweetAlert
                show={this.state.successAlertCancel}
                title="Erfolg"
                type='success'
                text="Transfer erfolgreich abgebrochen."
                onConfirm={() => this.setState({ successAlertCancel: false })}
                />

                <SweetAlert
                show={this.state.errorAlert}
                title="Fehlgeschlagen"
                type='error'
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ errorAlert: false })}
                />

            </div>
        )
    }
}

export default Transfer
