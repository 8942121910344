import React, { Component } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../Loading';

export class Marketplace extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            applications: null
        }
    }

    async componentDidMount(){
        fetch('https://scapi.schnelle.cloud/sc/items/marketplace?sort=position', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                console.log("error")
            } else {
                this.setState({applications: data.data})
                this.setState({loading: false})
            }
        });
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.applications) {return <Loading />}

        const applications = this.state.applications.map((application, key) =>
            <Col key={application.id} xs={12} lg={6} xl={4} style={{ marginBottom: '20px' }}>
                <Link to={ "/marketplace/" + application.id }>
                    <Paper elevation={0} className="card-body">
                        <div className="d-flex flex-row align-items-center">
                            
                            <div style={{ marginBottom: '5px' }}>
                                <Typography className="application-view-title" variant="span">
                                    <Image style={{ borderRadius: '50px', marginBottom: '3px', marginRight: '10px', border: '1px solid #eeeeee' }} height="30px" src={application.image_url} />
                                    { application.name }
                                </Typography>
                            </div>
                            <div className="ml-auto" style={{ paddingBottom: '4px' }}>
                                { application.latest_version }
                            </div>
                        </div>
                        <hr style={{margin: '3px 0px 10px 0px'}} />
                        <div className="d-flex flex-row" style={{ marginTop: '10px' }}>
                            <div style={{ fontSize: '12px' }}>
                                { application.description.substr(0, 135) + "..." }
                            </div>
                        </div>
                        <hr style={{margin: '10px 0px 10px 0px'}} />
                            <div className="d-flex flex-row" style={{fontSize: '12px'}}>
                                <div>Datenbank: {application.database_type}</div>
                                <div style={{ marginLeft: 'auto' }}>Preis: ab {application.min_price}</div>
                            </div>
                    </Paper>
                </Link>
            </Col>
        );

        return (
            <div>
                <Row>
                    {applications}
                </Row>
            </div>
        )
    }
}

export default Marketplace
