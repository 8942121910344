import React, { Component } from 'react';
import ApplicationsView from '../applications/ApplicationsView'

export class Dashboard extends Component {
    render() {
        return (
            <div>
                <ApplicationsView />
            </div>
        )
    }
}

export default Dashboard
