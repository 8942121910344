import React, { Component } from 'react';
import { Row, Col} from 'react-bootstrap';
import { TextField, Paper, Typography, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import SweetAlert from 'sweetalert2-react';
import Loading from '../Loading';

export class ApplicationSafety extends Component {

    constructor(props) {
        super(props);
        this.state = {
            successAlert: false,
            errorAlert: false,
            application: {},
            loading: true
        }
    }

    componentDidMount() {
        const authToken = localStorage.getItem("authToken");
        fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.application.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                window.location = "/"
            } else {
                this.setState({application: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.application.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                window.location = "/"
            } else {
                this.setState({application: data.data})
                this.setState({loading: false})
            }
        });
        }, 30000);
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.application) {return <Loading />}

        const application = this.state.application;

        function appliationLocked(locked,locked2,status, status2) {
            if(locked === true || locked2 === true) {return 'disabled';}
            if(status === 'updating' || status2 === 'updating') {return 'disabled';}
            else {return '';}
          }

        if(this.state.application.locked !== this.props.applicationisLocked ) {
            const authToken = localStorage.getItem("authToken");
            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.application.id}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            })
            .then(data => data.json())
            .then(data => {
                if(data.error) {
                } else {
                    this.setState({application: data.data})
                    this.setState({loading: false})
                }
            });
        }

        return (
            <Row>

                <Col xs={12} style={{ marginBottom: '20px' }}>
                    <Paper elevation={0} className="card-body" style={{ paddingBottom: '10px' }}>
                        <Row>
                        <Col xs={12} className="mb-3">
                                <div className="d-flex flex-row">
                                    <div>
                                    <Typography variant="h6">
                                        Sicherheit
                                    </Typography>
                                    </div>
                                    <div className="ml-auto">
                                        <Button variant="contained" color="primary" size="small" className="sc-save-button" disabled={appliationLocked(application.locked,this.props.appliationLocked,application.status, this.props.application.status)} onClick={() => {

                                            function sshUsername() {
                                                let placeholder = document.getElementById("application-ssh-username").placeholder;
                                                let value = document.getElementById("application-ssh-username").value;
                                                if(value){
                                                    return value;
                                                } else {
                                                    return placeholder;
                                                }
                                            }

                                            function sshPassword() {
                                                let value = document.getElementById("application-ssh-password").value;
                                                if(value){
                                                    return value;
                                                }
                                            }

                                            function sshPublickey() {
                                                let placeholder = document.getElementById("application-ssh-publickey").placeholder;
                                                let value = document.getElementById("application-ssh-publickey").value;
                                                if(value){
                                                    return value;
                                                } else {
                                                    return placeholder;
                                                }
                                            }

                                            function sshProtectionPassword() {
                                                let value = document.getElementById("application-protection-password").value;
                                                if(value){
                                                    return value;
                                                }
                                            }
                                            
                                            const authToken = localStorage.getItem("authToken");

                                            fetch(`https://scapi.schnelle.cloud/sc/items/applications/${application.id}`, {
                                                method: 'PATCH',
                                                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                                body: JSON.stringify({
                                                    ssh_username: sshUsername(),
                                                    ssh_password: sshPassword(),
                                                    ssh_publickey: sshPublickey(),
                                                    protection_password: sshProtectionPassword(),
                                                    status: 'updating'
                                                })
                                            })
                                            .then(data => data.json())
                                            .then(data => {
                                                if(data.data) {
                                                    
                                                    fetch(`https://scapi.schnelle.cloud/sc/items/applications/${this.props.application.id}`, {
                                                        method: 'GET',
                                                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                                    })
                                                    .then(data => data.json())
                                                    .then(data => {
                                                        if(data.error) {
                                                            window.location = "/"
                                                        } else {
                                                            this.setState({application: data.data})
                                                            this.setState({loading: false})
                                                            document.getElementById("application-ssh-username").value = ''
                                                            document.getElementById("application-ssh-publickey").value = ''
                                                            document.getElementById("application-ssh-password").value = ''
                                                            document.getElementById("application-protection-password").value = ''
                                                            this.setState({ successAlert: true })
                                                        }
                                                    });
                                                } else {
                                                    this.setState({ errorAlert: true })
                                                }
                                            });
                                        }} >
                                            <FontAwesomeIcon style={{ fontSize: '16px', color: '#999999' }} icon={faSave} />
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={3}>
                                <div>
                                <TextField fullWidth id="application-ssh-username" className="mr-4 mb-3 d-block" type="text" label="SSH Username" placeholder={application.ssh_username} />
                                </div>
                            </Col>
                            <Col xs={12} lg={3}>
                                <div>
                                <TextField fullWidth id="application-ssh-publickey" className="mr-4 mb-3 d-block" type="text" label="SSH Publickey" placeholder={application.ssh_publickey}/>
                                </div>
                            </Col>
                            <Col xs={12} lg={3}>
                                <div>
                                <TextField autocomplete="off" fullWidth id="application-ssh-password" className="mr-4 mb-3 d-block" type="password" label="SSH Password" />
                                </div>
                            </Col>
                            <Col xs={12} lg={3}>
                                <div>
                                <TextField autocomplete="off" fullWidth id="application-protection-password" className="mr-4 mb-3 d-block" type="password" label="Protection Password" />
                                </div>
                            </Col>
                        </Row>
                    </Paper>
                </Col>

                <SweetAlert
                show={this.state.successAlert}
                title="Anwendung angepasst"
                type='success'
                text="Deine Anwendung wird in kürze neu gestartet."
                onConfirm={() => this.setState({ successAlert: false })}
                />

                <SweetAlert
                show={this.state.errorAlert}
                title="Fehlgeschlagen"
                type='error'
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ errorAlert: false })}
                />

            </Row>
        )
    }
}

export default ApplicationSafety
