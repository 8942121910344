import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { Paper, Typography, TextField, Button } from '@material-ui/core';
import Loading from '../components/Loading';
import SweetAlert from 'sweetalert2-react';

export class Support extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tickets: null
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/items/support_in?sort=-id', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({tickets: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch('https://scapi.schnelle.cloud/sc/items/support_in?sort=-id', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({tickets: data.data})
                this.setState({loading: false})
            }
        });
          }, 60000);
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.tickets) {return <Loading />}

        const tickets = this.state.tickets.map((ticket, key) =>
            <Paper className="card-body" elevation={0} style={{ marginBottom: '20px' }}>
                <Row>
                    <Col xs={12}>
                        <h5>{ticket.subject}</h5>
                        <p style={{ marginBottom: 0 }}>{ticket.content}</p>
                        {ticket.answer ? (<div><hr /><h5 style={{ marginTop: 10 }}>Antwort:</h5><p style={{ marginBottom: 0 }}>{ticket.answer}</p></div>) : ('')}
                    </Col>
                </Row>
            </Paper>
        );

        return (
            <div>
                <Paper className="card-body" elevation={0} style={{ marginBottom: '20px' }}>
                    <Typography variant="h6" style={{ marginBottom: '10px' }}>
                        Nachricht senden
                    </Typography>
                    <TextField style={{ marginBottom: '20px' }} id="support-subject" fullWidth className="d-block" type="text" label="Betreff"/>
                    <TextField style={{ marginBottom: '0px' }} id="support-text" fullWidth className="d-block" type="text" multiline rows={5} label="Kurze Beschreibung"/>
                    <Button style={{ marginTop: '20px' }} variant="contained" color="primary" onClick={() => {
                        const authToken = localStorage.getItem("authToken");
                        fetch(`https://scapi.schnelle.cloud/sc/items/support_in`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                            body: JSON.stringify({
                                status: 'draft',
                                subject: document.getElementById("support-subject").value,
                                content: document.getElementById("support-text").value
                            })
                        })
                        .then(data => data.json())
                        .then(data => {
                            if(data.data) {
                                document.getElementById("support-subject").value = ''
                                document.getElementById("support-text").value = ''
                                const authToken = localStorage.getItem("authToken");
                                fetch('https://scapi.schnelle.cloud/sc/items/support_in?sort=-id', {
                                    method: 'GET',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.error) {
                                    } else {
                                        this.setState({ showSupportSuccess: true })
                                        this.setState({tickets: data.data})
                                    }
                                });
                            } else {
                                this.setState({ showSupportError: true })
                            }
                        });
                    }}>Senden</Button>
                </Paper>

                {tickets}

                <SweetAlert
                show={this.state.showSupportSuccess}
                type="success"
                title="Anfrage erfolgreich"
                text="Wir habe deine Anfrage erhalten."
                onConfirm={() => this.setState({ showSupportSuccess: false })}
                />

                <SweetAlert
                show={this.state.showSupportError}
                type="error"
                title="Fehlgeschlagen"
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ showSupportError: false })}
                />
            </div>
        )
    }
}

export default Support
