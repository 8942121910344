import React, { Component } from 'react';
import { TextField, Paper, Typography, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';

export class Sign extends Component {

    constructor(props){
        super(props);
        this.state = {
            showLogin: 'd-block',
            showRegister: 'd-none',
            RegisterSuccessAlert: false,
            ErrorAlert: false,
            showOTPField: 'd-none',
            faEnabled: false
        }
    }

    render() {

        return (
            <Row className="d-flex justify-content-center">

                <Col xs={12} lg={6} className={this.state.showLogin} style={{ marginBottom: '20px' }}>
                    <Paper elevation={0} className="card-body">
                        <Typography className="mb-4" variant="h5" component="h1">
                            Anmelden
                        </Typography>
                        <form noValidate autoComplete="off">
                            <TextField fullWidth id="signin-email" className="mr-4 mb-3 d-block" type="email" label="E-Mail Adresse" />
                            <TextField fullWidth id="signin-password" className="mr-4 d-block" type="password" label="Passwort" />
                            <div className={this.state.showOTPField}>
                                <TextField fullWidth id="signin-otp" className="mr-4 mt-3 d-block" type="text" label="OTP" />
                            </div>
                        </form>
                        <div style={{ marginTop: '30px' }} className="d-flex flex-row">
                            <Button onClick={() =>
                                {
                                    if(this.state.faEnabled === false){
                                        fetch('https://scapi.schnelle.cloud/sc/auth/authenticate', {
                                            method: 'POST',
                                            headers: { 'Content-Type': 'application/json' },
                                            body: JSON.stringify({
                                                email: document.getElementById("signin-email").value,
                                                password: document.getElementById("signin-password").value
                                            })
                                        })
                                        .then(data => data.json())
                                        .then(data => {
                                            if(data.data){
                                                localStorage.setItem("authToken", data.data.token)
                                                window.location.href = '/';
                                            }
                                            if(data.error.code === 111){
                                                console.log("missing 2fa")
                                                this.setState({ showOTPField: 'd-block' })
                                                this.setState({ faEnabled: true })
                                            }
                                            else {
                                                this.setState({ ErrorAlert: true })
                                            }
                                        });
                                    }
                                    if(this.state.faEnabled === true){
                                        fetch('https://scapi.schnelle.cloud/sc/auth/authenticate', {
                                            method: 'POST',
                                            headers: { 'Content-Type': 'application/json' },
                                            body: JSON.stringify({
                                                email: document.getElementById("signin-email").value,
                                                password: document.getElementById("signin-password").value,
                                                otp: document.getElementById("signin-otp").value
                                            })
                                        })
                                        .then(data => data.json())
                                        .then(data => {
                                            if(data.data){
                                                localStorage.setItem("authToken", data.data.token)
                                                window.location.href = '/';
                                            }
                                            else {
                                                this.setState({ ErrorAlert: true })
                                            }
                                        });
                                    }
                                }
                            } variant="contained" color="primary" className="sign-button">
                                Anmelden
                            </Button>
                            <Typography onClick={() =>
                                {this.setState({
                                    showLogin: 'd-none',
                                    showRegister: 'd-block'
                                })}
                            } className="ml-auto" style={{ fontSize: '14px', cursor: 'pointer', color: '#0088C8' }} variant="p" component="p">
                                <div className="signin-text">Noch nicht registriert? Jetzt Kundenkonto erstellen!</div>
                            </Typography>
                        </div>
                    </Paper>
                </Col>

                <Col xs={12} lg={6} className={this.state.showRegister} style={{ marginBottom: '20px' }}>
                    <Paper elevation={0} className="card-body">
                        <Typography className="mb-4" variant="h5" component="h1">
                            Registrieren
                        </Typography>
                        <form noValidate autoComplete="off">
                            <TextField fullWidth id="signup-firstname" className="mr-4 mb-3 d-block" type="text" label="Vorname *" />
                            <TextField fullWidth id="signup-lastname" className="mr-4 mb-3 d-block" type="text" label="Nachname *" />
                            <TextField fullWidth id="signup-email" className="mr-4 mb-3 d-block" type="email" label="E-Mail Adresse *" />
                            <TextField fullWidth id="signup-phone" className="mr-4 mb-3 d-block" type="email" label="Telefonnummer *" />
                            <TextField fullWidth id="signup-address" className="mr-4 mb-3 d-block" type="email" label="Straße *" />
                            <TextField fullWidth id="signup-postalcode" className="mr-4 mb-3 d-block" type="email" label="Postleitzahl *" />
                            <TextField fullWidth id="signup-city" className="mr-4 mb-3 d-block" type="email" label="Stadt *" />
                            <TextField fullWidth id="signup-state" className="mr-4 mb-3 d-block" type="email" label="Bundesland *" />
                            <TextField fullWidth id="signup-country" className="mr-4 mb-3 d-block" type="email" label="Land *" />
                            <FormControlLabel className="mt-1"
                                control={
                                <Checkbox
                                    checked="True"
                                    value="checkedB"
                                    color="primary"
                                />
                                }
                                label="Ich bin mit der Verwendung meiner eingegebenen Daten zur Verarbeitung meiner Anfrage einverstanden und akzeptiere die allgemeinen Geschäftsbedingungen."
                            />
                            <a style={{ color: '#0088C8', fontSize: '12px' }} href="https://schnelle.cloud/agb">Allgemeine Geschäftsbedingungen</a>
                        </form>
                        <div style={{ marginTop: '20px' }} className="d-flex flex-row">
                            <Button onClick={() =>
                                {
                                    function signupFirstname() {
                                        if(document.getElementById("signup-firstname").value.length >= 3){
                                            return document.getElementById("signup-firstname").value;
                                        }
                                    }
                                    function signupLastName() {
                                        if(document.getElementById("signup-lastname").value.length >= 3){
                                            return document.getElementById("signup-lastname").value;
                                        }
                                    }
                                    function signupEmail() {
                                        if(document.getElementById("signup-email").value.length >= 6){
                                            return document.getElementById("signup-email").value;
                                        }
                                    }
                                    function signupPhone() {
                                        if(document.getElementById("signup-phone").value.length >= 8){
                                            return document.getElementById("signup-phone").value;
                                        }
                                    }
                                    function signupAddress() {
                                        if(document.getElementById("signup-address").value.length >= 6){
                                            return document.getElementById("signup-address").value;
                                        }
                                    }
                                    function signupPostalcode() {
                                        if(document.getElementById("signup-postalcode").value.length >= 4){
                                            return document.getElementById("signup-postalcode").value;
                                        }
                                    }
                                    function signupCity() {
                                        if(document.getElementById("signup-city").value.length >= 4){
                                            return document.getElementById("signup-city").value;
                                        }
                                    }
                                    function signupState() {
                                        if(document.getElementById("signup-state").value.length >= 3){
                                            return document.getElementById("signup-state").value;
                                        }
                                    }
                                    function signupCountry() {
                                        if(document.getElementById("signup-country").value.length >= 4){
                                            return document.getElementById("signup-country").value;
                                        }
                                    }
                                    fetch('https://scapi.schnelle.cloud/sc/items/users', {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json' },
                                        body: JSON.stringify({
                                            firstname: signupFirstname(),
                                            lastname: signupLastName(),
                                            email: signupEmail(),
                                            phone: signupPhone(),
                                            address: signupAddress(),
                                            postalcode: signupPostalcode(),
                                            city: signupCity(),
                                            state: signupState(),
                                            country: signupCountry(),
                                            status: 'draft'
                                        })
                                    })
                                    .then(data => data.json())
                                    .then(data => {
                                        if(data.public){
                                            this.setState({ RegisterSuccessAlert: true })
                                            setTimeout(function(){ window.location.href = "/"; }, 2000);
                                        } else {
                                            this.setState({ ErrorAlert: true })
                                        }
                                    });

                                }
                            } variant="contained" color="primary" className="sign-button">
                                Registrieren
                            </Button>
                            <Typography onClick={() =>
                                {this.setState({
                                    showLogin: 'd-block',
                                    showRegister: 'd-none'
                                })}
                            } className="ml-auto" style={{ fontSize: '13px', cursor: 'pointer', color: '#0088C8' }} variant="p" component="p">
                                <div className="signin-text">Bereits Kunde?</div>
                            </Typography>
                        </div>
                    </Paper>
                </Col>

                <SweetAlert
                show={this.state.RegisterSuccessAlert}
                title="Kundenkonto erstellt"
                type="success"
                text="Checke dein E-Mail Postfach für weitere Anweisungen."
                onConfirm={() => this.setState({ RegisterSuccessAlert: false })}
                />

                <SweetAlert
                show={this.state.ErrorAlert}
                title="Fehlgeschlagen"
                type="error"
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ ErrorAlert: false })}
                />

            </Row>
        )
    }
}

export default Sign
