import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { Paper, Typography, TextField, Button } from '@material-ui/core';
import SweetAlert from 'sweetalert2-react';
import Loading from '../components/Loading';

export class AccountSSH extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showSSHSuccess: false,
            showSSHError: false,
            keys: null,
            showRemoveKeyModal: false,
            keyToRemove: null,
            keyNameToRemove: null
        }
    }

    async componentDidMount(){
        const authToken = localStorage.getItem("authToken");
        fetch('https://scapi.schnelle.cloud/sc/items/ssh_keys?sort=-id', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                localStorage.removeItem("authToken")
                window.location = "/"
            } else {
                this.setState({keys: data.data})
                this.setState({loading: false})
            }
        });
        setInterval(() => {
            fetch('https://scapi.schnelle.cloud/sc/items/ssh_keys?sort=-id', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
            })
            .then(data => data.json())
            .then(data => {
                if(data.error) {
                    localStorage.removeItem("authToken")
                    window.location = "/"
                } else {
                    this.setState({keys: data.data})
                    this.setState({loading: false})
                }
            });
          }, 60000);
    }

    render() {

        if(this.state.loading) {return <Loading />}

        if(!this.state.keys) {return <Loading />}

        function handleSSHKeyInput(input) {
            if (input.length < 176){
                
            }
            else {
                return input
            }
        }

        function handleSSHUserInput(input) {
            if (input.length < 3){
                
            }
            else {
                return input
            }
        }

        const keys = this.state.keys.map((key) =>
            <Paper className="card-body" elevation={0} style={{ marginBottom: '20px' }}>
                <TextField style={{ marginBottom: '20px' }} autocomplete={false} id="ssh-name" fullWidth className="d-block" type="text" label="Bezeichnung" value={key.name}/>
                <TextField style={{ marginBottom: '20px' }} autocomplete={false} id="ssh-user" fullWidth className="d-block" type="text" label="User" value={key.user}/>
                <TextField style={{ marginBottom: '0px' }} multiline rows={5} id="ssh-key" fullWidth className="d-block" type="text" label="SSH Key" value={key.key}/>
                <Button style={{ marginTop: '20px' }} variant="contained" color="secondary" onClick={() => {
                    this.setState({ keyToRemove: key.id, keyNameToRemove: key.name, showRemoveKeyModal: true })
                }}>Entfernen</Button>
            </Paper>
        );

        return (
            <div>
                <Paper className="card-body" elevation={0} style={{ marginBottom: '20px' }}>
                    <Typography variant="h6" style={{ marginBottom: '10px' }}>
                        SSH-Key hinzufügen
                    </Typography>
                    <TextField style={{ marginBottom: '20px' }} autocomplete={false} id="ssh-name" fullWidth className="d-block" type="text" label="Bezeichnung"/>
                    <TextField style={{ marginBottom: '20px' }} autocomplete={false} id="ssh-user" fullWidth className="d-block" type="text" label="User"/>
                    <TextField style={{ marginBottom: '0px' }} multiline rows={5} id="ssh-key" fullWidth className="d-block" type="text" label="SSH-Key"/>
                    <Button style={{ marginTop: '20px' }} variant="contained" color="primary" onClick={() => {
                        const authToken = localStorage.getItem("authToken");
                        fetch(`https://scapi.schnelle.cloud/sc/items/ssh_keys`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                            body: JSON.stringify({
                                status: 'draft',
                                name: document.getElementById("ssh-name").value,
                                key: handleSSHKeyInput(document.getElementById("ssh-key").value),
                                user: handleSSHUserInput(document.getElementById("ssh-user").value)
                            })
                        })
                        .then(data => data.json())
                        .then(data => {
                            if(data.data) {
                                document.getElementById("ssh-name").value = ''
                                document.getElementById("ssh-user").value = ''
                                document.getElementById("ssh-key").value = ''
                                fetch('https://scapi.schnelle.cloud/sc/items/ssh_keys?sort=-id', {
                                    method: 'GET',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                })
                                .then(data => data.json())
                                .then(data => {
                                    if(data.error) {
                                        localStorage.removeItem("authToken")
                                        window.location = "/"
                                    } else {
                                        this.setState({ showSSHSuccess: true })
                                        this.setState({keys: data.data})
                                        this.setState({loading: false})
                                    }
                                });
                            } else {
                                this.setState({ showSSHError: true })
                            }
                        });
                    }}>Speichern</Button>
                </Paper>

                {keys}

                <SweetAlert
                show={this.state.showSSHSuccess}
                type="success"
                title="Anfrage erfolgreich"
                text="Informationen erfolgreich hinterlegt."
                onConfirm={() => this.setState({ showSSHSuccess: false })}
                />

                <SweetAlert
                show={this.state.showSSHError}
                type="error"
                title="Fehlgeschlagen"
                text="Überprüfe deine Angaben und versuche es erneut."
                onConfirm={() => this.setState({ showSSHError: false })}
                />

                <div>
                    <Modal show={this.state.showRemoveKeyModal}>
                        <Modal.Header closeButton onClick={() => {this.setState({ showRemoveKeyModal: false })}}>
                        <Modal.Title>SSH-Key entfernen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ marginBottom: '10px' }}>Dieser Vorgang kann nicht Rückgängig gemacht werden.</div>
                            <div style={{ marginBottom: '30px' }}>SSH-Key: <strong>{this.state.keyNameToRemove}</strong></div>
                            <div style={{ marginBottom: '10px' }}>Vollständige Bezeichnung des zu entfernenden SSH-Keys:</div>
                            <TextField id="delete-key-name" fullWidth className="d-block" type="text" label="Name des SSH-Keys"/>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button id="cancel-remove-application-button" variant="secondary" onClick={() => {
                            this.setState({ showRemoveKeyModal: false })
                        }}>
                            Abbrechen
                        </Button>
                        <Button style={{ marginLeft: '10px' }} variant="contained" color="secondary" onClick={() => {
                            let name = this.state.keyNameToRemove;
                            let delete_name = document.getElementById("delete-key-name").value;

                            if(name === delete_name){
                                const authToken = localStorage.getItem("authToken");
                                fetch(`https://scapi.schnelle.cloud/sc/items/ssh_keys/${this.state.keyToRemove}`, {
                                    method: 'PATCH',
                                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                    body: JSON.stringify({
                                        status: 'deleted'
                                    })
                                })
                                .then(Response => {
                                    if(Response.status === 204) {
                                        fetch('https://scapi.schnelle.cloud/sc/items/ssh_keys?sort=-id', {
                                            method: 'GET',
                                            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
                                        })
                                        .then(data => data.json())
                                        .then(data => {
                                            if(data.error) {
                                                localStorage.removeItem("authToken")
                                                window.location = "/"
                                            } else {
                                                this.setState({ showSSHSuccess: true })
                                                this.setState({keys: data.data})
                                                this.setState({loading: false})
                                            }
                                        });
                                        this.setState({ showRemoveKeyModal: false })
                                    } else {
                                        this.setState({ showSSHError: true })
                                    }
                                });
                            } else {
                                this.setState({ showSSHError: true })
                            }

                        }}>
                            Entfernen
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

            </div>
        )
    }
}

export default AccountSSH
